//Dependencies
import { Button, Checkbox, DatePicker, Divider, Icon, Input, InputNumber, Modal, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//Components
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import Titles from "../../GenericComponents/titles.js";

//Actions
import {
  insertCreditByUser,
  listFrequencyUnits,
  listScDepartmentByScDependencyId,
  listScDependenciesBySoDependencyId,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
  listUsersWithoutCredit,
} from "../../../actions";

//Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

const { Column } = Table;
let usersToAsignCreditTemp = [];

class AddCreditByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scDependencies: [],
      usersWithoutCredit: null,
      scDepartments: [],
      scDepartmentSelected: null,
      scUserProfiles: [],
      scUserProfileSelected: null,
      startDate: null,
      endDate: null,
      loadingAdd: false,
      usersChecked: false,
      isChecked: false,
      searchTerm: "",
      disabledSelectors: true,
      disableCheckbox: true,
      disableAssignButton: true,
      scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null, //variable to filter the users
      scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null, //variable to filter the users
      scCountryName: this.props.location.props ? this.props.location.props.scCountryName : null,
      usersArray: [],
      //Flag used to redirect to /listCreditByUser if any scDependency was selected
      redirectBackToListCreditByUser: false,

      // Object to add
      usersToAsignCreditTemp: [],
      cashCredit: null,
      frequencyUnitId: null,
      frequencyValue: null,

      disableEndDatePicker: true,
    };
    this.onSearchUserEvent = this.onSearchUserEvent.bind(this);
    this.handleOkNoScDependencyWarning = this.handleOkNoScDependencyWarning.bind(this);
  }

  componentDidMount() {
    const { listUsersWithoutCredit, listScDepartmentByScDependencyId, listScUserProfilesByScDependencyId, listFrequencyUnits } = this.props;
    const { scDependencyId } = this.state;

    if (!scDependencyId) {
      //Shows No Product Modal Warning
      return <div> {this.noScDependencyWarning()} </div>;
    }

    // listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    if (scDependencyId != null) {
      listUsersWithoutCredit(scDependencyId, "", "", "", (response) => {
        this.setState({
          usersWithoutCredit: response.data.data,
        });
      });
      listScDepartmentByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScUserProfilesByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }

    listFrequencyUnits((response) => {
      if (response.data.data) {
        const nativeFrequencyUnits = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        this.setState({
          frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
        });
      }
    });

    const date = moment.utc(moment()).local().format();
    this.setState({
      startDate: _.split(date, "T")[0] + " 00:00:00",
      endDate: null,
      isEnabled: false,
    });
  }

  //Events
  scDepartmentInputEvent(event) {
    const { listUsersWithoutCredit } = this.props;
    usersToAsignCreditTemp = [];

    const value = event.value;
    this.setState({
      scDepartmentSelected: value,
      scUserProfileSelected: null,
    });

    listUsersWithoutCredit("", value, "", "", (response) => {
      this.setState({
        usersWithoutCredit: response.data.data,
      });
    });
  }

  scUserProfileInputEvent(event) {
    const { listUsersWithoutCredit } = this.props;
    usersToAsignCreditTemp = [];

    const value = event.value;
    this.setState({
      scUserProfileSelected: value,
      scDepartmentSelected: null,
    });

    listUsersWithoutCredit("", "", value, "", (response) => {
      this.setState({
        usersWithoutCredit: response.data.data,
      });
    });
  }

  creditInputEvent(event) {
    const { usersArray, startDate, frequencyUnitId, frequencyValue } = this.state;

    const value = _.trimStart(event);
    this.setState({ cashCredit: value });

    if (usersArray.length > 0 && value > 0 && startDate && frequencyUnitId != null && frequencyValue) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  frequencyValueInputEvent(event) {
    const { usersArray, startDate, frequencyUnitId, cashCredit } = this.state;

    const value = _.trimStart(event);
    this.setState({ frequencyValue: value });

    if (usersArray.length > 0 && cashCredit && startDate && frequencyUnitId != null && value > 0) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  frecuencyUnitSelect(event) {
    const { usersArray, startDate, frequencyValue, cashCredit } = this.state;

    this.setState({
      disabledDetails: false,
      disableCheckbox: false,
      disabledSelectors: false,
    });

    const value = event.value;
    this.setState({ frequencyUnitId: value });

    if (usersArray.length > 0 && cashCredit && startDate && value > 0 && frequencyValue) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  startDateEvent = (date, dateString) => {
    const { isChecked, endDate, startDate } = this.state;
    const startDateLocal = moment(date).format();
    if (isChecked === true) {
      if (endDate < startDateLocal) {
        this.setState({ startDate: startDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ startDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ startDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date, dateString) => {
    const { isChecked, endDate, startDate } = this.state;

    const endDateLocal = moment(date).format();
    if (isChecked === true) {
      if (endDateLocal < startDate) {
        this.setState({ endDate: endDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ endDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ endDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    const { startDate, disableEndDatePicker } = this.state;

    const endDateLocal = moment(startDate).format();
    if (e.target.checked === true) {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: true,
      });
    } else {
      this.setState({
        endDate: null,
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: false,
      });
    }
  }

  onCurrencySelect = (currency) => {
    this.setState({ currencyCodeId: currency.value });
  };

  onSearchUserEvent(event) {
    const { users } = this.props;
    this.setState({ users: users.data, searchTerm: event.target.value });
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const { cashCredit, startDate, frequencyUnitId, frequencyValue } = this.state;
      this.setState({ usersArray: selectedRowKeys });
      if (selectedRows.length > 0 && cashCredit && startDate && frequencyUnitId != null && frequencyValue) {
        this.setState({
          disableAssignButton: false,
        });
      } else {
        this.setState({
          disableAssignButton: true,
        });
      }
    },
  };

  addCreditByUserEvent = (e) => {
    const { insertCreditByUser, listUsersWithoutCredit } = this.props;
    const { scDependencyId, usersArray, cashCredit, startDate, endDate, frequencyUnitId, frequencyValue, currencyCodeId } = this.state;
    this.setState({
      usersWithoutCredit: [],
      loadingAdd: true,
      disableAssignButton: true,
      disableCheckbox: true,
    });

    let infoDto = {
      userIds: usersArray,
      creditAssigned: cashCredit,
      cashRuleStartDate: moment(startDate).format(),
      cashRuleEndDate: endDate ? moment(endDate).format() : null,
      frequencyUnitId: frequencyUnitId,
      frequencyValue: frequencyValue,
      currencyCodeId: currencyCodeId,
    };

    if (infoDto) {
      insertCreditByUser(infoDto, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          //Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          //Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          //Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({
            disableCheckbox: false,
            infoDto: null,
            disableAssignButton: true,
            loadingAdd: false,
            isChecked: false,
            cashCredit: null,
            frequencyUnitId: null,
            frequencyValue: null,
            scDepartmentSelected: null,
            scUserProfileSelected: null,
          });
          usersToAsignCreditTemp = [];

          listUsersWithoutCredit(scDependencyId, "", "", "", (response) => {
            this.setState({
              usersWithoutCredit: response.data.data,
            });
          });
        } else {
          //Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      //Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { scDependencyId } = this.state;
    const { listUsersWithoutCredit } = this.props;

    this.setState({
      loadingInfo: true,
      identityCardNumber: selectedKeys[0],
    });

    listUsersWithoutCredit(scDependencyId, "", "", selectedKeys[0], (response) => {
      this.setState({
        usersWithoutCredit: response.data.data,
        loadingInfo: false,
      });
    });
  };

  handleReset = (clearFilters) => {
    const { scDependencyId } = this.state;
    const { listUsersWithoutCredit } = this.props;

    this.setState({
      loadingInfo: true,
    });
    listUsersWithoutCredit(scDependencyId, "", "", "", (response) => {
      this.setState({
        usersWithoutCredit: response.data.data,
        loadingInfo: false,
      });
    });

    clearFilters();
    this.setState({ identityCardNumber: "" });
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }

  handleOkNoScDependencyWarning() {
    this.setState({ redirectBackToListCreditByUser: true });
  }

  //Information to render
  render() {
    const { currencyCodesByCountry } = this.props;
    const {
      redirectBackToListCreditByUser,
      scDependencyName,
      cashCredit,
      frequencyValue,
      frequencyUnits,
      frequencyUnitId,
      disableStartDatePicker,
      disableEndDatePicker,
      startDate,
      endDate,
      isChecked,
      scDepartments,
      scDepartmentSelected,
      disabledSelectors,
      scUserProfiles,
      scUserProfileSelected,
      usersWithoutCredit,
      loading,
      disableAssignButton,
      loadingAdd,
      scCountryName,
      currencyCodeId,
    } = this.state;

    if (redirectBackToListCreditByUser) {
      //Redirects to CreditByUser from No Product Modal Warning
      return <Redirect to="/listCreditByUser" />;
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.creditByUser.creditAssignation} tooltip={Strings.users.creditByUser.creditAssignationTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <h3>{scDependencyName}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.creditByUser.creditSelection} </h3>
            </Divider>
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.vmcashvaulttype.credit + ": "} tooltip={Strings.users.creditByUser.creditAssignationTooltip}></TextWithInfoTooltip>
          </div>
          <div className="col col-md-2 vertSpace">
            <InputNumber value={cashCredit} isReq={true} min={1} onChange={(e) => this.creditInputEvent(e)} />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.currency}
              name="currencyCodeId"
              options={currencyCodesByCountry}
              defaultValue={currencyCodeId}
              isReq
              tooltip={Strings.product.currencyTooltip}
              onChange={(option) => this.onCurrencySelect(option)}
            />
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col-6 col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.dispensationRule.frequency + ": "} tooltip={Strings.dispensationRule.frequencyTooltip}></TextWithInfoTooltip>
          </div>
          <div className="col-6 col-md-2 vertSpace">
            <InputNumber value={frequencyValue} isReq min={1} onChange={(e) => this.frequencyValueInputEvent(e)} />
          </div>
          <div className="col col-md-6 vertSpace">
            <SelectGeneral
              text={Strings.dispensationRule.frequencyUnit}
              options={frequencyUnits}
              defaultValue={frequencyUnitId}
              isReq
              onChange={(e) => this.frecuencyUnitSelect(e)}
            />
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col-xs-6 col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.dispensationRule.startDate + ": "}></TextWithInfoTooltip>
          </div>
          <div className="col-xs-6 col-md-2 vertSpace">
            <DatePicker value={moment(startDate)} onChange={(date, dateString) => this.startDateEvent(date, dateString)} required disabled={disableStartDatePicker} />
          </div>

          <div className="col-xs-6 col-md-2 vertSpace" style={{ padding: "0px" }}>
            <TextWithInfoTooltip name={Strings.dispensationRule.endDate + ": "}></TextWithInfoTooltip>
          </div>
          <div className="col-xs-6 col-md-4 vertSpace">
            <Checkbox style={{ marginRight: "10px" }} checked={isChecked} onChange={(e) => this.nullEndDateHandleChange(e)} />
            <DatePicker value={moment(endDate)} onChange={(date, dateString) => this.endDateEvent(date, dateString)} required={false} disabled={disableEndDatePicker} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.userSelection}</h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 vertSpace">
            <SelectGeneral
              text={Strings.scDepartment.department}
              tooltip={Strings.scDepartment.departmentTooltipp}
              options={scDepartments}
              onChange={(e) => this.scDepartmentInputEvent(e)}
              defaultValue={scDepartmentSelected}
              disabled={disabledSelectors}
            />
          </div>

          <div className="col-md-6 vertSpace">
            <SelectGeneral
              text={Strings.scUserProfile.userProfile}
              tooltip={Strings.scUserProfile.userProfileTooltip}
              options={scUserProfiles}
              onChange={(e) => this.scUserProfileInputEvent(e)}
              defaultValue={scUserProfileSelected}
              disabled={disabledSelectors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col vertSpace">
            <Table
              dataSource={usersWithoutCredit}
              bordered
              loading={loading}
              pagination={false}
              size="middle"
              style={{ width: "100%", overflow: "scroll", height: "300px", overflowX: "hidden" }}
              scroll={{ x: "auto" }}
              rowKey="id"
              rowSelection={this.rowSelection}
            >
              <Column key="id" title={Strings.users.identityCardNumber} dataIndex="identityCardNumber" {...this.getColumnSearchProps("identityCardNumber")} />
              <Column
                title={Strings.users.user}
                render={(row) => (
                  <div>
                    {row.firstName} {row.middleName ? row.middleName : ""} {row.lastName1} {row.lastName2 ? row.lastName2 : ""}
                  </div>
                )}
              />
              <Column title={Strings.scDepartment.department} render={(row) => <div>{!row.scDepartmentName ? "--" : row.scDepartmentName}</div>} />
              <Column title={Strings.scUserProfile.userProfile} render={(row) => <div>{!row.scUserProfileName ? "--" : row.scUserProfileName}</div>} />
            </Table>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            {usersToAsignCreditTemp.length > 0 ? (
              <div>
                <h5 align="left">
                  <b>{Strings.users.usersSelected + ":"}</b> {" " + usersToAsignCreditTemp.length}
                </h5>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link={"/listCreditByUser"} />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton onClick={(e) => this.addCreditByUserEvent(e)} isDisabled={disableAssignButton} loading={loadingAdd} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currencyCodesByCountry: state.currencyCodeByCountry,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listFrequencyUnits,
  listUsersWithoutCredit,
  insertCreditByUser,
})(AddCreditByUser);
