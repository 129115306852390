// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import { AddButton, ExportButton } from "../../GenericComponents/buttons/index";
import Loading from "../../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import Titles from "../../GenericComponents/titles.js";

import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";

// Actions
import {
  disableCreditByUser,
  exportCreditByUserReport,
  listAllCurrencyCodesByCountry,
  listCreditByUser,
  listScDepartmentByScDependencyId,
  listScDependenciesBySoDependencyId,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
} from "../../../actions";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalization } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

// import "react-table/react-table.css";
// import "./listDispensationRuleByUser.css";

const { Column } = Table;

// const dateFormat = 'DD/MM/YYYY hh:mm:ss a';

class ListCreditByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scDependencies: [],
      scDepartments: [],
      scUserProfiles: [],

      scDependencyId: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyId : null) : null,
      scDependencyName: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyName : null) : null,
      scCountryName: null,

      enabledScDependency: true,
      disabledScEmployeeInformationFilter: true,

      scUserProfileId: null,
      scDepartmentId: null,

      isTableLoading: false,
      identityCardNumber: "",

      creditsByUser: [],
      creditsByUserCount: 0,

      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,

      scDependencieId: 0,
      users: [],
      dependencieType: null,
      addButtonDisabled: true,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyName },
      soScSelectionProps,
      listCreditByUser,
      
      listAllCurrencyCodesByCountry,
      listScDepartmentByScDependencyId,
      listScUserProfilesByScDependencyId,
    } = this.props;

    const { page, pageSize, attribute, order } = this.state;

    listAllCurrencyCodesByCountry(this.props.userDependency.stockOwnerCountryName);

    if (dependencyType === "so") {
      this.setState({
        soDependencyId: dependencyId,
      });
      // Get scDependencyId from store
      if (soScSelectionProps.sc) {
        this.setState({
          scDependencyId: soScSelectionProps.sc.id,
          scDependencyName: soScSelectionProps.sc.label,
          scCountryName: soScSelectionProps.sc.countryName,
          isTableLoading: true,
        });

        const scDepartmentId = soScSelectionProps.scDepartment ? soScSelectionProps.scDepartment.id : null;
        const scUserProfileId = soScSelectionProps.scUserProfile ? soScSelectionProps.scUserProfile.id : null;

        listCreditByUser(soScSelectionProps.sc.id, scDepartmentId, scUserProfileId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            creditsByUser: response.data.data.content,
            creditsByUserCount: response.data.data.totalElements,
            isTableLoading: false,
            disabledScEmployeeInformationFilter: false,
            addButtonDisabled: false,
          });
        });

        listScDepartmentByScDependencyId(soScSelectionProps.sc.id, (response) => {
          this.setState({
            scDepartments: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        listScUserProfilesByScDependencyId(soScSelectionProps.sc.id, (response) => {
          this.setState({
            scUserProfiles: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
      }
    }

    if (dependencyType === "sc") {
      this.setState({
        scDependencyId: dependencyId,
        scDependencyName: dependencyName,
        scCountryName: soScSelectionProps.sc?.countryName,

        isTableLoading: true,
        addButtonDisabled: false,

        disabledScEmployeeInformationFilter: false,
        disabledEnrollment: false,

        scDepartmentId: null,
        scUserProfileId: null,
        identityCardNumber: "",
      });

      const scDepartmentId = soScSelectionProps.scDepartment ? soScSelectionProps.scDepartment.id : null;
      const scUserProfileId = soScSelectionProps.scUserProfile ? soScSelectionProps.scUserProfile.id : null;

      listCreditByUser(dependencyId, scDepartmentId, scUserProfileId, "", page, pageSize, attribute, order, (response) => {
        this.setState({
          creditsByUser: response.data.data.content,
          creditsByUserCount: response.data.data.totalElements,
          isTableLoading: false,
          disabledScEmployeeInformationFilter: false,
          addButtonDisabled: false,
        });
      });

      this.props.listScDepartmentByScDependencyId(dependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      this.props.listScUserProfilesByScDependencyId(dependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value } = event;

    this.setState({
      soDependencyId: value,
      scDependencyId: null,
      scDependencyName: null,
      scCountryName: null,

      scDepartments: [],
      scDepartmentId: null,
      scUserProfiles: [],
      scUserProfileId: null,

      addButtonDisabled: true,
      disabledScEmployeeInformationFilter: true,

      identityCardNumber: "",

      creditsByUser: [],
      creditsByUserCount: 0,
      page: 1,
      pageSize: 10,
    });
  };

  scDependencyHandleChange = (event) => {
    const { value: scDependencyId, label: scDependencyName, countryName: scCountryName } = event;
    const { listCreditByUser, listScDepartmentByScDependencyId, listScUserProfilesByScDependencyId } = this.props;
    const { attribute, order } = this.state;
    this.setState({
      scDependencyId,
      scDependencyName,
      scCountryName,

      scDepartments: [],
      scDepartmentId: null,
      scUserProfiles: [],
      scUserProfileId: null,

      addButtonDisabled: false,
      disabledScEmployeeInformationFilter: false,

      isTableLoading: true,
      identityCardNumber: "",

      creditsByUser: [],
      creditsByUserCount: 0,
      page: 1,
      pageSize: 10,
    });

    listCreditByUser(scDependencyId, null, null, "", 1, 10, attribute, order, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });

    listScDepartmentByScDependencyId(scDependencyId, (response) => {
      this.setState({
        scDepartments: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
    listScUserProfilesByScDependencyId(scDependencyId, (response) => {
      this.setState({
        scUserProfiles: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  };

  scDepartmentHandleChange(event) {
    const { value } = event;
    const { listCreditByUser } = this.props;
    const { scDependencyId, identityCardNumber, attribute, order } = this.state;

    this.setState({
      isTableLoading: true,
      scUserProfileId: null,
      scDepartmentId: value,

      identityCardNumber: "",

      page: 1,
      pageSize: 10,
    });

    listCreditByUser(scDependencyId, value, null, identityCardNumber, 1, 10, attribute, order, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  }

  scUserProfileHandleChange(event) {
    const { value } = event;
    const { listCreditByUser } = this.props;
    const { scDependencyId, identityCardNumber, attribute, order } = this.state;

    this.setState({
      isTableLoading: true,
      scDepartmentId: null,
      scUserProfileId: value,

      identityCardNumber: "",

      page: 1,
      pageSize: 10,
    });

    listCreditByUser(scDependencyId, null, value, identityCardNumber, 1, 10, attribute, order, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { listCreditByUser } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, attribute, order } = this.state;
    if (selectedKeys.length > 0) {
      this.setState({
        isTableLoading: true,
        page: 1,
        pageSize: 10,
        identityCardNumber: selectedKeys[0],
      });

      listCreditByUser(scDependencyId, scDepartmentId, scUserProfileId, selectedKeys[0], 1, 10, attribute, order, (response) => {
        this.setState({
          creditsByUser: response.data.data.content,
          creditsByUserCount: response.data.data.totalElements,
          isTableLoading: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    const { listCreditByUser } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, attribute, order } = this.state;

    this.setState({
      isTableLoading: true,
      page: 1,
      pageSize: 10,
      identityCardNumber: "",
    });

    listCreditByUser(scDependencyId, scDepartmentId, scUserProfileId, "", 1, 10, attribute, order, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });

    clearFilters();
  };

  showPagination = (page, pageSize) => {
    const { listCreditByUser } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, attribute, order } = this.state;
    this.setState({
      isTableLoading: true,
      page,
      pageSize,
    });

    listCreditByUser(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, page, pageSize, attribute, order, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  }

  onSort = (pagination, filters, sorter) => {
    const { listCreditByUser } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, page, pageSize } = this.state;
    let sortKey = "DESC"
    if (sorter.order === "ascend") {
      sortKey = "ASC"
    }

    this.setState({
      isTableLoading: true,
      page: 1,
      attribute: sorter.columnKey,
      order:sortKey,
    });

    listCreditByUser(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, page, pageSize, sorter.columnKey, sortKey, (response) => {
      this.setState({
        creditsByUser: response.data.data.content,
        creditsByUserCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  };

  // Export methods
  showExportReportsModal = () => {
    const { creditsByUserCount } = this.state;
    if (creditsByUserCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible) => {
    this.setState({
      isExportModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportCreditByUserReport } = this.props;
    const { exportLoading, scDependencyId } = this.state;

    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.generalTerms.name,
        Strings.users.id,
        Strings.scDepartment.department,
        Strings.scUserProfile.userProfile,
        Strings.users.creditByUser.creditAssigned,
        Strings.dispensationRule.frequency,
        Strings.dispensationRule.frequencyUnit,
        Strings.users.creditByUser.creditAvailable,
        Strings.dispensationRule.startDate,
        Strings.dispensationRule.endDate,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      exportCreditByUserReport(scDependencyId, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          const today = new Date();
          link.setAttribute("download", `${Strings.users.creditByUser.creditByUserReport} ${today}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  disableCreditByUserEvent = (e, row) => {
    const { disableCreditByUser, listCreditByUser } = this.props;
    const { creditsByUser, scDependencyId, scDepartmentId, scUserProfileId, page, pageSize, attribute, order } = this.state;
    this.setState({
      acceptButtonDisabled: true,
      loading: true,
    });

    const userIds = [];

    if (row) {
      userIds.push(row.id);
    } else if (!row) {
      creditsByUser.forEach((item) => {
        userIds.push(item.id);
      });
    }

    disableCreditByUser(userIds, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
          onOk: this.handleOk,
        });
        this.setState({ acceptButtonDisabled: false });
        listCreditByUser(scDependencyId, scDepartmentId, scUserProfileId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            creditsByUser: response.data.data.content,
            creditsByUserCount: response.data.data.totalElements,
            isTableLoading: false,
            page: 1,
            pageSize: 10,
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  roundAndFormat = (value) => {
    return value.toFixed(1).replace(".", ",");
  };

  // Information to render
  render() {
    const {
      props: { soDependencies },
    } = this;
    const {
      permissions: { data },
    } = this.props;

    const {
      soDependencyId,
      scDependency,
      scDependencyId,
      scDependencyName,
      scCountryName,
      isExportModalVisible,
      isExceedModalVisible,
      scDepartments,
      scDepartmentId,
      scUserProfiles,
      scUserProfileId,
      disabledScEmployeeInformationFilter,
      addButtonDisabled,
      creditsByUser,
      isTableLoading,
      creditsByUserCount,
      page,
      exportLoading,
    } = this.state;

    if (!soDependencies) {
      return <Loading />;
    }

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.users.creditByUser.creditByUser} tooltip={Strings.users.creditByUser.creditAssignationTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector hasSc onSoDependencyChange={this.soDependencyHandleChange} onScDependencyChange={this.scDependencyHandleChange} />

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scDepartment.department}
              tooltip={Strings.scDepartment.departmentTooltip}
              options={scDepartments}
              onChange={(e) => this.scDepartmentHandleChange(e)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={scDepartmentId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scUserProfile.userProfile}
              tooltip={Strings.scUserProfile.userProfileTooltip}
              options={scUserProfiles}
              onChange={(e) => this.scUserProfileHandleChange(e)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={scUserProfileId}
            />
          </div>

          <div className="vertSpace col-12">
            <div className="col-xs-0 col-md-6" />
            <ValidatePermissionForComponent permission="PRIVILEGE CREDIT BY USER PUT" permissions={data}>
              <div className="vertSpace col-md-6">
                <AddButton
                  link={addButtonDisabled ? "" : "/addCreditByUser"}
                  tooltip={Strings.users.addExecutiveUsersTooltip}
                  props={
                    addButtonDisabled
                      ? null
                      : {
                          scDependencyId,
                          scDependencyName,
                          scCountryName
                        }
                  }
                  disabled={addButtonDisabled}
                />
              </div>
            </ValidatePermissionForComponent>
          </div>
        </div>
        <Divider orientation="left">
          <h3>{Strings.users.creditByUser.creditByUser} </h3>
        </Divider>
        <QueryResponse
          isLoading={isTableLoading}
          dataSourceLength={creditsByUser.length}
          callback={this.methodListCreaditByUser(
            creditsByUser,
            isTableLoading,
            scDepartmentId,
            scDepartments,
            scUserProfileId,
            scUserProfiles,
            data,
            soDependencyId,
            scDependency,
            creditsByUserCount,
            page,
            scDependencyId,
            scCountryName,
            exportLoading
          )}
        />
      </div>
    );
  }

  methodListCreaditByUser(
    creditsByUser,
    isTableLoading,
    scDepartmentId,
    scDepartments,
    scUserProfileId,
    scUserProfiles,
    data,
    soDependencyId,
    scDependency,
    creditsByUserCount,
    page,
    scDependencyId,
    scCountryName,
    exportLoading
  ) {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Table onChange={this.onSort} dataSource={creditsByUser} bordered loading={isTableLoading} pagination={false} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
              <Column
                aling="center"
                title={Strings.users.identityCardNumber}
                dataIndex="identityCardNumber"
                {...this.getColumnSearchProps("identityCardNumber")}
                filtered
              />
              <Column aling="center" title={Strings.generalTerms.name} render={(row) => <div style={{ minWidth: "100px" }}>{row.fullName}</div>} />
              {!scDepartmentId && scDepartments.length > 0 ? (
                <Column aling="center" title={Strings.scDepartment.department} render={(row) => <div>{!row.scDepartmentName ? "--" : row.scDepartmentName}</div>} />
              ) : (
                ""
              )}
              {!scUserProfileId && scUserProfiles.length > 0 ? (
                <Column aling="center" title={Strings.scUserProfile.userProfile} render={(row) => <div>{!row.scUserProfileName ? "--" : row.scUserProfileName}</div>} />
              ) : (
                ""
              )}
              <Column 
                aling="center" 
                title={Strings.users.creditByUser.creditAssigned} 
                key={"cashCredit"}
                sorter={true} 
                render={(row) =>
                  row.creditAssigned ? (
                    <span>
                      {(row.currencyCodeSymbol ? row.currencyCodeSymbol : '') +
                        ` ${this.roundAndFormat(row.creditAssigned)} ` +
                        (row.currencyCodeAlphaCode ? row.currencyCodeAlphaCode : '')}
                    </span>
                  ) : (
                    "--"
                  )
                }
              />
              <Column aling="center" title={Strings.dispensationRule.frequency} dataIndex="frequencyValue" />
              <Column
                aling="center"
                title={Strings.dispensationRule.frequencyUnit}
                dataIndex="frequencyUnitName"
                render={(frequencyUnitName) => <div>{FreqUnitNameLocalization(frequencyUnitName)} </div>}
              />
              <Column 
                aling="center" 
                title={Strings.users.creditByUser.creditAvailable} 
                key={"cashAvailable"}
                sorter={true}
                render={(row) =>
                  row.creditAvailable ? (
                    <span>
                      {(row.currencyCodeSymbol ? row.currencyCodeSymbol : '') +
                        ` ${this.roundAndFormat(row.creditAvailable)} ` +
                        (row.currencyCodeAlphaCode ? row.currencyCodeAlphaCode : '')}
                    </span>
                  ) : (
                    "--"
                  )
                }
              />
              <Column
                aling="center"
                title={Strings.dispensationRule.startDate}
                key={"cashRuleStartDate"}
                render={(row) => <div style={{ minWidth: "100px" }}>{!row.startDate ? "--" : moment(row.startDate).format(DATE_TIME_FORMAT)}</div>}
                sorter={(a, b) => moment(a.startDate) - moment(b.startDate)}
              />
              <Column
                aling="center"
                title={Strings.dispensationRule.endDate}
                key={"cashRuleEndDate"}
                render={(row) => <div style={{ minWidth: "100px" }}>{!row.endDate ? "--" : moment(row.endDate).format(DATE_TIME_FORMAT)}</div>}
                sorter={(a, b) => moment(a.endDate) - moment(b.endDate)}
              />

              {ValidatePermissionForComponentPart("PRIVILEGE CREDIT BY USER PUT", data) && (
                <Column
                  aling="center"
                  title={Strings.generalTerms.edit}
                  width="5%"
                  align="center"
                  render={(row) => (
                    <Link
                      to={{
                        pathname: "/editCreditByUser",
                        props: {
                          soDependencyId,
                          scDependency,
                          scCountryName,
                          creditByUser: row,
                        },
                      }}
                    >
                      <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                    </Link>
                  )}
                />
              )}

              {ValidatePermissionForComponentPart("PRIVILEGE CREDIT BY USER PUT", data) && (
                <Column
                  aling="center"
                  title={Strings.generalTerms.delete}
                  width="5%"
                  align="center"
                  render={(row) => (
                    <Popconfirm
                      title={Strings.generalResponses.confirmDeleteMessage}
                      onConfirm={(e) => this.disableCreditByUserEvent(e, row)}
                      okText={Strings.generalTerms.yes}
                      cancelText={Strings.generalTerms.no}
                    >
                      <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                    </Popconfirm>
                  )}
                />
              )}
            </Table>

            <div className="vertSpace col-12">
              <div className="col-md-6" />
              <div className="vertSpace col-md-6">
                <Pagination
                  size="small"
                  total={creditsByUserCount}
                  showSizeChanger
                  onChange={this.showPagination}
                  onShowSizeChange={this.showPagination}
                  hideOnSinglePage={false}
                  pageSizeOptions={["10", "25", "50", "100", "250"]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
                  current={page}
                  disabled={creditsByUser ? !(creditsByUser.length > 0) : true}
                />
              </div>
            </div>
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE CREDIT BY USER REPORT GET" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={!scDependencyId} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listCreditByUser,
  listAllCurrencyCodesByCountry,
  disableCreditByUser,
  exportCreditByUserReport,
})(ListCreditByUser);
