import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  // Spanish
  es: {
    communications: {
      communications: "Comunicaciones",
      communicationsMonitor: "Monitor de comunicaciones",
      communicationsMonitorTooltip: "Monitorea las estadísticas de comunicación de las máquinas",
      connectionIndicators: "Indicadores de conexión",
      metricType: "Tipo de métrica",
      metricTypeTooltip: "Seleccione el tipo de métrica de comunicaciones que deseas graficar",
      requests: "Solicitudes",
      requestsTooltip: "Solicitudes realizadas al Noacloud",
      ping: "Latencia",
      speed: "Velocidad",
      signal: "Señal",
      success: "Éxito",
      fail: "Fallo",
      noResponse: "No Res",
      signalIntensity: "Intensidad de señal",
      traffic: "Tráfico",
      successRequests: "Solicitudes exitosas",
      successRequestsTooltip: "Solicitudes exitosas realizadas al Noacloud (http 200 y 201)",
      failRequests: "Solicitudes fallidas",
      failRequestsTooltip: "Solicitudes fallidas realizadas al Noacloud (http diferentes a 200 y 201). Las solicitudes son reintentadas",
      noResponseRequests: "Solicitudes sin respuesta",
      noResponseRequestsTooltip: "Solicitudes sin respuesta realizadas al Noacloud. Las solicitudes son reintentadas",
      connectionServiceIndex: "Índice calidad conexión",
      connectionServiceIndexTooltip: "Índice que relaciona las solicitudes exitosas sobre las el total de solicitudes",
      uploadTraffic: "Tráfico de subida",
      uploadTrafficTooltip: "Tráfico de subida al Noacloud en kB",
      downloadTraffic: "Tráfico de bajada",
      downloadTrafficTooltip: "Tráfico de bajada desde Noacloud en kB",
      download: "Bajada",
      upload: "Subida",
      pingAverage: "Latencia promedio",
      pingAverageTooltip: "latencia promedio en ms para prueba de ping",
      uploadSpeedAverage: "Velocidad promedio de subida",
      uploadSpeedAverageTooltip: "Velocidad promedio de subida. Solo diponible si el setupParameter MEASURE CONNECTION SPEED está habilitado",
      downloadSpeedAverage: "Velocidad promedio de bajada",
      downloadSpeedAverageTooltip: "Velocidad promedio de bajada. Solo diponible si el setupParameter MEASURE CONNECTION SPEED está habilitado",
      signalIntensityAverage: "Promedio de intensidad de la señal",
      signalIntensityAverageTooltip:
        "Intensidad promedio de señal. Solo disponible si la máquina usa WiFi o tiene un modem 4G conectado directamente (Sin router). Si el modem es Huawei la intensidad se muestra en niveles (0-5), sino en dB",
      seeMetrics: "Ver métricas",
    },
    dependencyType: {
      dependencyType: "Tipo de cliente",
      dependencyTypeTooltip: "Tipo de cliente (directo o indirecto)",
    },
    dispensationReport: {
      delivered: "Entregado",
      dispensations: "Dispensaciones",
      dispensationReports: "Reportes de dispensación",
      dispensationTooltip: "Dispensaciones realizadas por los usuarios en las Vending Machines",
      dispensationDateTooltip: "Rango de fechas en el del cual se van a consultar las dispensaciones",
      returned: "Retornado",
      transactionDate: "Fecha de la transacción",
      transactionDateTooltip: "Es la fecha en la que la máquina realizó la transacción",
      transactionId: "Id de transacción",
      storageDate: "Fecha de almacenamiento",
      storageDateTooltip: "Es la fecha en la que la transacción se guardó en el sistema",
      syncDate: "Fecha de sincronización",
      userId: "Id de usuario",
      userImage: "Imagen del usuario",
      productImage: "Imagen del producto",
    },
    dispensationRule: {
      addDispensationRule: "Creación de regla de dispensación",
      addDispensationRuleTooltip: "Crea una nueva regla de dispensación para el cliente indirecto seleccionado",
      addProducts: "Agregar productos",
      addProductsTooltip: "Seleccione productos para agregar a la regla de dispensación. Sólo se muestran los productos que previamente se han asociado al cliente indirecto.",
      allowedAmount: "Cantidad habilitada",
      allowedAmountTooltip: "Cantidad habilitada del producto para que pueda ser dispensado por el usuario",
      alreadyExistsDispensationRuleError: "Ya existe una regla de dispensación llamada: ",
      amount: "Cantidad",
      assignedDispensationRules: "Reglas de dispensación asignadas al cliente",
      availableAmount: "Cantidad disponible",
      availableAmountTooltip: "Cantidad disponible para ser dispensada",
      configProducts: "Configurar frecuencia y cantidad",
      configProductsTooltip: "Configurar la frecuencia de cada producto asociado a la regla y sus cantidades permitidas",
      disableScDispensationRuleMsgConfirmaiton: "Las asignaciones a usuarios de dicha regla también serán deshabilitadas. ¿Desea continuar?",
      dispensationRuleByIndirectClient: "Rules by indirect client",
      dispensationByUser: "Reglas de dispensación por usuario",
      dispensationByUserTooltip: "Regla de dispensación con la cual el usuario podrá dispensar ciertos productos",
      dispensationFrequency: "Frecuencia de dispensación",
      dispensationRule: "Regla de dispensación",
      dispensationRuleDescription: "Descripción regla de dispensación",
      dispensationRuleReport: "Reporte de reglas de dispensación",
      dispenationRuleDetails: "Detalles de la regla de dispensación",
      dispensationRuleProducts: "Productos asignados a la regla de dispensación",
      dispensationRules: "Reglas de dispensación",
      dispensationRuleState: "Estado de la regla de dispensación",
      dispensationRulesTooltip: "Lista las reglas de dispensación para el cliente indirecto seleccionado",
      dispensedAmount: "Cantidad dispensado",
      editDispensationRule: "Editar las reglas de dispensación",
      editDispensationRuleTooltip: "Editar los parámetros de las reglas de dispensación",
      editProdAssignment: "Editar configuración del producto",
      editScDispensationRule: "Editar regla de dispensación",
      editScDispensationRuleErrorMsg1: "Error de configuración del producto. ",
      editScDispensationRuleErrorMsg2: "Error de configuración del grupo de productos. ",
      editScDispensationRuleErrorMsg3: ". Seleccione una unidad de frecuencia.",
      editScDispensationRuleTooltip: "Parametrización de la regla de dispensación",
      endDate: "Fecha de cierre",
      endDateTooltip: "Fecha de cierre de la regla de dispensación",
      errorNoProducts: "Debe asignar por lo menos un producto a la regla de dispensación.",
      errorProdConfig: "Por favor revise la configuración de frecuencia y cantidad de los productos e inténtelo nuevamente.",
      frequency: "Frecuencia",
      frequencyTooltip: "Frecuencia con la que puede se dispensar",
      frequencyUnit: "Unidad de frecuencia",
      frequencyUnitTooltip: "Unidad de Frecuencia en la que puede dispensar",
      nameTooltip: "Nombre a asignar a la regla de dispensación",
      noDispRuleSelected: "No se ha seleccionado una regla de dispensación",
      noDispRuleSelectedMsg: "Por favor seleccione una regla de dispensación para editarla",
      noEndDate: "Sin fecha de cierre",
      noEndDateTooltip: "La regla de dispensación no tendrá fecha de cierre",
      scDispensationRuleDescription: "Descripción de la regla de dispensación",
      scDispensationRuleName: "Nombre de la regla de dispensación",
      scDispensationRulesAvailables: "Reglas de dispensación disponibles",
      scDispensationRuleState: "Estado de la regla de dispensación",
      selectedProductWarningMsg1: "No es posible seleccionar el producto de forma individual, ya que está contenido en uno de los grupos de productos seleccionados (",
      selectedProductWarningMsg2: "). Elimine la asignación por grupo e intente de nuevo.",
      selectedVmProductGroupWarningMsg1: "No es posible seleccionar el grupo de producto, porque contiene uno de los productos asignados de forma individual (",
      selectedVmProductGroupWarningMsg2: "). Elimine la asignación individual e intente de nuevo.",
      selectedVmProductGroupWarningMsg3: "No es posible seleccionar el grupo de producto. Ya fue seleccionado otro grupo que contiene productos en común",
      insertProductToVmProductGroupWarning:
        "No se pudo incluir uno de los productos en el grupo de productos. Es posible que este producto esté contenido en una de dispensación de forma individual o a través de un grupo de productos que incluya este grupo de productos. Debe eliminar la asignación y volver a intentarlo.",
      showOnlyDisabledDispenseRules: "Mostrar solo las reglas de dispensación deshabilitadas",
      soProductAssignmentToDispensationRule: "Asignación de productos base a la regla de dispensación",
      startDate: "Fecha de inicio",
      startDateTooltip: "Fecha de inicio de la regla de dispensación",
      startEndDateToAssign: "Parametrización de la fecha de inicio y fin de la regla de dispensación",
      stateToolTip: "Estado de la regla de dispensación",
    },
    dispensationRuleByUser: {
      addDispensationRuleByUser: "Asignar reglas de dispensación a usuarios",
      dispensationRuleByUser: "Regla de dispensación por usuario",
      dispensationRuleByUserTooltip: "Regla de dispensación asociadas a un usuario",
      dispensationRuleToAssign: "Selección de regla de dispensación",
      editDispensationRuleByUser: "Editar regla de dispensación del usuario",
      editDispensationRuleByUserTooltip: "Editar regla de dispensación asociadas a un usuario",
      selectUser: "Seleccionar usuario",
      dispensationRuleByUserReport: "Reporte reglas de dispensación por usuario",
    },
    eventType: {
      eventCategory: "Categoría del evento",
      eventQuantity: "Cantidad de eventos",
      eventType: "Tipo de evento",
      priority: "Prioridad",
      priorityTooltip: "Rango de prioridad del evento (bajo, medio, alto)",
      eventTypeCategoryName: "Categoría de tipo de evento",
      seeTable: "Ver tabla",
      seeTableTooltip: "Ver los eventos organizados cronológicamente en una tabla",
      seeChart: "Ver gráfica",
      seeChartTooltip: "Ver los eventos agrupados en histogramas con número de ocurrencias",
    },
    fileImport: {
      additionalFields: "Campos adicionales",
      dateFormat: "Fomato de fecha",
      dispensationRuleUpdateOptions: "Remplazar reglas de dispensación",
      dispensationRuleUpdateOptionsTooltip:
        "Acciones a ejecutar cuando el usuario ya tiene una regla de dispensación habilitada. Reemplazar: Si la regla a asignar ya la tiene el usuario entonces la información del archivo será omitida. Si la regla es diferente entonces deshabilita la regla anterior y crea una nueva con la información del archivo. Omitir: Omitir la información de regla de dispensación del archivo. La fecha de inicio es asignada a la regla y no a los usuarios.",
      downloadFailedRegistersFile: "Archivo de registros fallidos",
      downloadTemplate: "Plantilla",
      importFinished: "Importación finalizada",
      importRegistersCSV: "Importar registros por archivo csv",
      invalidColPairing: "No es posible asignar dos veces el mismo campo. Por favor revise el emparejamiento",
      mandatoryFields: "Campos obligatorios",
      pairFields: "Empareje los campos del archivo cargado para que concuerden con los campos requeridos para la importación",
      reenableUserEnrollment: "Recuperar última información de enrolamiento",
      reenableUserEnrollmentTooltip:
        "Si un usuario ya se encuentra en el sistema pero fue previamente eliminado, su información de enrolamiento será recuperada. De lo contrario esta información será omitida.",
      registersBadFormat: "Los siguientes registros no tienen el formato requerido",
      SuccessFull: "Se importaron correctamente",
      registers: "registros",
      registersTransaction: "registros obtenidos de la consulta",
      registersTransactionContent: "Este proceso de exportación para archivo plano csv puede tardar un poco, para iniciar seleccione ok",
      registersFailed:
        "Los siguientes registros poseen información inválida por el sistema y no pudieron ser importados. Por favor revise la información de los registros fallidos y vuelva a intentar.",
      registersOmited:
        "Los siguientes registros fueron omitidos pues ya se encontraban en el sistema. Si desea actualizar su información por la del archivo csv habilite la opción Actualizar registros ya existentes",
      selectFileWithFields: "Seleccione un archivo csv que contenga los siguientes campos separados por punto y coma (;)",
      selectImportOptions: "Seleccione las opciones de importación que desee",
      updateExitingRegisters: "Actualizar información básica del usuario",
      updateExitingRegistersTooltip:
        "Al seleccionar esta opción, la información de los usuarios será actualizada por la indicada en el archivo csv. Si el archivo contiene usuarios previamente eliminados, estos serán rehabilitados y su información será actualizada. Si la opción no es seleccionada la información del archivo csv será omitida.",
    },
    firmwareVersion: {
      additionalFile: "Archivos adicionales",
      addFirmwareVersionTooltip: "Formulario para añadir nuevas versiones de firmware para máquinas de primera y segunda generación",
      editFirmwareVersionTooltip: "Formulario para editar las versiones de firmware para máquinas de primera y segunda generación",
      additionalFilePlaceholder: "Nombre del archivo",
      additionalFileComment: "Descripción del archivo",
      deleteFirmwareVersionWarn: "¿Desea eliminarlo?",
      descriptionInstructionsFile: "Descripción del archivo de instrucciones",
      descriptionLibScript: "Descripción del libScript",
      descriptionNoaVmcmanagerJar: "Descripción del noaVmcmanager Jar",
      descriptionNoaVmcCashlessJar: "Descripción del noaVmcCashless Jar",
      descriptionNoaVmcAIDetectorJar: "Descripción del noaVmcAIDetector Jar",
      descriptionNoaVmcJar: "Descripción del noaVmc Jar",
      descriptionSqlScript: "Descripción del sqlScript",
      descriptionToolTip: "Descripción de la versión del firmware",
      detailsTooltip: "Detalles de la versión de firmware",
      editFirmwareVersion: "Editar información de la versión de firmware",
      editFirmwareVersionWarn: "¿Desea editarlo?",
      instructionsFile: "Archivo de instrucciones",
      libScript: "LibScript",
      libScriptPlaceholder: "Nombre del archivo LibScript",
      noaVmc: "NoaVmc",
      noaVmcPlaceholder: "Nombre del archivo NoaVmc",
      noaVmcJar: "NoaVmc Jar",
      noaVmcManager: "NoaVmcManager",
      noaVmcManagerPlaceholder: "Nombre del archivo NoaVmcManager",
      noaVmcmanagerJar: "NoaVmcmanager Jar",
      noaVmcCashless: "NoaVmcCashless",
      noaVmcCashlessPlaceholder: "Nombre del archivo NoaVmcCashless",
      noaVmcCashlessJar: "NoaVmcCashless Jar",
      noaVmcAIDetector: "NoaVmcAIDetector",
      noaVmcAIDetectorPlaceholder: "Nombre del archivo NoaVmcAIDetector",
      noaVmcAIDetectorJar: "NoaVmcAIDetector Jar",
      sqlScript: "SqlScript",
      sqlScriptPlaceholder: "Nombre del archivo SqlScript",
      version: "Versión",
      versionTooltip: "Nombre de la versión del firmware",
      versionsLimit: "Límite",
      versionsLimitTooltip: "Indica el número de versiones previas con las que será compatible la nueva versión",
      versionRequirements: "Requerimientos de la versión",
    },
    generalResponses: {
      authError: "Error en la autenticación",
      authErrorTooltip: "Se presentó un error en la autenticación, será redireccionado al login",
      alreadyExistsError: "Al menos uno de los valores ingresados ya se encuentra asignado en el sistema.",
      alreadyExistsErrorSoproduct: "El código del producto ingresado ya se encuentra creado en el sistema.",
      alreadyExistsErrorSoproductRepeat: "El producto que intenta borrar pertenece a la información configurada de un planograma.",
      answer: "Respuesta",
      isError500: "Algo salió mal en el servidor.",
      pageNoFound: "Página no encontrada",
      pageNoFoundUrl: "Por favor comprueba la URL.",
      clickHere: "Haz click aquí",
      clickHereUndirection: " para ser redirigido a la página de inicio.",
      connectionError: "Error de conexión",
      connectionErrorTooltip: "No se puede conectar al servidor",
      confirmDeleteAllMessage: "¿Está seguro que desea eliminar todos los elementos?",
      confirmDeleteMessage: "¿Está seguro que desea eliminar el elemento?",
      confirmationMessage: "Seleccione el botón OK para iniciar o de lo contrario cancele la solicitud, modifique los parámetros de consulta e intente de nuevo.",
      confirmationMessageToExportTransactionReportPart1: "Está intentando exportar",
      confirmationMessageToExportTransactionReportPart2:
        "El sistema validará si permite iniciar la descarga directa del archivo o si envía de forma automática el reporte a su correo electrónico.",
      creationError: "Error de creación",
      creationSuccess: "La información ha sido creada exitosamente",
      deleteingScProdInfoVmAssigned:
        "El producto se encuentra actualmente asignado a una Vending Machine o Locker. Realice el aprovisionamiento físico, elimine el producto del planograma y luego deshabilite el producto al cliente.",
      deletionSuccess: "El regstro ha sido eliminado exitosamente",
      deletionError: "La información no ha sido eliminada. Intente nuevamente.",
      deletionErrorTitle: "Error de la eliminación",
      downloadConfirmation: "El reporte fue generado y será descargado en su navegador",
      errorUpdating: "Error en la actualización",
      failedTransaction: "Transacción fallida",
      fileExportRequest: "¿Desea exportar el reporte?",
      generalError: "Algo ha ocurrido de manera inesperada. Por favor, vuelva a tratar o póngase en contacto con Soporte Técnico.",
      haveAsyncRequestInProcess: "Tiene una solicitud de informe de transacción de producto asíncrono pendiente o en proceso",
      imageError: "Error cargando la imagen",
      infoSuccessfullyRegistered: "La información ha sido registrada exitosamente",
      invalidEndDateError: "Fecha de finalización no válida",
      invalidStartDateError: "Fecha de inicio no válida",
      invalidEmail: "Se requiere el correo electrónico para realizar una solicitud de informe de transacción de producto asíncrono",
      invalidError: "La información ingresada no es correcta. Por favor revise los parámetros de los campos obligatorios e inténtelo nuevamente",
      invalidNullFields: "Algunos campos son obligatorios para realizar una solicitud de informe de transacción de producto asíncrono",
      invalidNumberOfFields: "El número de campos requeridos es invalido",
      invalidParameters: "Parámetros inválidos",
      invalidValue: "Valor inválido",
      keyIsAssigned: "El registro se encuentra asignado a otro elemento del sistema.",
      loadingViewMessage: "Estamos preparando el Noacloud para ti...",
      noResults: "Sin resultados",
      notificationOfAutomaticReportSubmissionTitle: "Notificación de envío automático de reportes",
      notificationOfAutomaticReportSubmissionPart1: "El número de registros a exportar es mayor a la cantidad permitida por el sistema para ser descargados de forma directa",
      notificationOfAutomaticReportSubmissionPart2: "El reporte de transacciones fue generado y enviado de forma automática a su correro electrónico",
      notificationOfAutomaticReportSubmissionPart3: "Espere un momento y verifique que haya recibido el reporte. En caso contrario intente nuevamente",
      searchInformation: "Información de la búsqueda",
      queryResponse: "No hay resultados asociados a los criterios de búsqueda selecionados",
      reportGenerationError: "Error al generar el reporte. Por favor intente de nuevo o comuníquese con Soporte Técnico",
      ruleWithAssignedUsers: "La regla tiene usuarios asignados. Por favor asigne una nueva regla a los usuarios antes de elminarla",
      saveError: "La información ingresada no ha sido guardada. Por favor intente de nuevo o comuníquese con Soporte Técnico",
      saveError2: "Los campos precio, impuesto y tipo de moneda son obligatorios. Diligencie la información e intente de nuevo",
      saveError3: "Los campos impuesto y tipo de moneda son obligatorios. Diligencie la información e intente de nuevo",
      saveSuccess: "La información ha sido guardada exitosamente",
      serverError: "Error del servidor",
      serviceError: "Error en servicio",
      successfulDelete: "Eliminación exitosa",
      successfullCreation: "Creación exitosa",
      successfulTransaction: "Transacción exitosa",
      successfulUpdate: "Actualización exitosa",
      transactionReportRegister: "¿Desea exportar los",
      transactionReportRegisterModalWarningTitle: "son demasiados registros para exportar",
      transactionReportRegisterModalWarningContent: "El máximo permitido es:",
      FIELD_VALUE_IS_EMPTY: "El campo no puede estar vacío",
      updatingGeneralParameters: "Actualización parámetros generales",
      assignedProducts: "Productos asignados",
      unassignedProducts: "Productos desasignados",
    },
    generalTerms: {
      accept: "Aceptar",
      add: "Agregar",
      additionalInfo: "Información adicional",
      address: "Dirección",
      adjust: "Ajustar",
      all: "Todos",
      age: "Edad",
      dateRecovery: "fecha de recuperación vigente",
      applyToAllVm: "Aplicar a todas las Vending Machines",
      assignedPl: "Asignados",
      assign: "Asignar",
      assignationDate: "Fecha de asignación",
      assignSettings: "Asignar configuración",
      attribute: "Atributo",
      available: "Disponible",
      basicInformation: "Información básica",
      byDefault: "Por defecto",
      cancel: "Cancelar",
      capacity: "Capacidad",
      caution: "Precaución",
      changePassword: "Cambiar contraseña",
      check: "Consultar",
      city: "Ciudad",
      code: "Código",
      codeToAssign: "Código a asignar",
      column: "Columna",
      columnTooltip: "Cantidad máxima de columnas del locker",
      columns: "Columnas",
      companyInfo: {
        aboutUs: "Acerca de nosotros",
        contactInformation: "Información de Contacto",
        contactUs: "Contáctenos",
        copyRight: "Todos los derechos reservados",
        privacyPolicies: "Políticas de privacidad",
        termnsAndConditions: "Términos y condiciones",
      },
      creationDate: "Fecha de creación",
      country: "País",
      ciuuCode: "Código CIIU",
      customer: "Cliente",
      date: "Fecha",
      delete: "Borrar",
      deleteWarn: "¿Esta seguro de eliminar?",
      deleteTaskWarning: "¿Esta seguro de eliminar esta tarea?",
      denomination: "Denominación",
      dependency: "Dependencia",
      deposit: "Depósito",
      description: "Descripción",
      details: "Detalles",
      disable: "Deshabilitar",
      disabledPl: "Deshabilitados",
      disabled: "Deshabilitado",
      doesNotApply: "No aplica",
      each: "cada",
      edit: "Editar",
      elapsedTime: "Tiempo transcurrido",
      elapsedTimeTooltip: "Tiempo transcurrido desde hoy sin recoger los productos",
      email: "Correo electrónico",
      emptyDescription: "Sin descripción",
      enable: "Habilitar",
      enabled: "Habilitado",
      enabledPl: "Habilitados",
      end: "Fin",
      enterId: "Ingrese el Id",
      every: "Cada",
      executive: "Corporativo",
      export: "Exportar",
      exportReport: "Generación de reporte",
      selectFileType: "Seleccione el tipo de archivo",
      failureType: "Tipo de fallo",
      filterBy: "Filtrar por",
      file: "Archivo",
      fileTransactionReport: "Advertencia: Este proceso de exportación para archivo plano csv puede tardar varios minutos ...",
      free: "Disp",
      funcionalities: "Funcionalidades",
      general: "General",
      generalParameters: "Parámetros generales",
      generalConfigs: "Configuraciones generales",
      home: "Inicio",
      ilimited: "Ilimitado",
      import: "Importar",
      important: "Importante",
      inConfiguration: "En configuración",
      information: "Información",
      informationNotAvailable: "Información no disponible",
      isEnabled: "¿Se encuentra habilitado?",
      items: "elementos",
      leftShort: "Izq",
      level: "Nivel",
      loading: "Cargando...",
      logout: "Salir",
      menu: "Menú",
      message: "Mensaje",
      mobil: "Celular",
      moreInfo: "Más información",
      myAccount: "Mi cuenta",
      myPersonalInformation: "Mi información personal",
      name: "Nombre",
      nameToAssign: "Nombre a asignar",
      no: "No",
      noacloudPharma: "Noacloud Pharma",
      notAssigned: "No asignado",
      notAssignedPl: "No asignados",
      notAvailable: "No disponible",
      of: "de",
      omit: "Omitir",
      parameterization: "Parametrización",
      phone: "Teléfono",
      position: "Posición",
      price: "Precio",
      province: "Departamento",
      registers: "Registros",
      remove: "Remover",
      replace: "Reemplazar",
      reportDate: "Fecha de reporte",
      reportType: "Tipo de reporte",
      requiredFields: "Campos requeridos",
      reset: "Reiniciar",
      restart: "Reiniciar",
      result: "Resultado",
      return: "Atrás",
      rightShort: "Der",
      row: "Fila",
      rowTooltip: "Cantidad máxima de filas del locker según el slot más pequeño",
      rows: "Filas",
      sales: "Ventas",
      save: "Guardar",
      search: "Buscar",
      searchById: "Buscar por Id",
      security: "Seguridad",
      seeMore: "Ver",
      select: "Seleccione",
      selection: "Selección",
      selectDate: "Seleccionar fecha",
      selectFile: "Seleccione un archivo",
      selectGender: "Seleccione un género",
      selectLocation: "Seleccione una ubicación",
      selectState: "Seleccione un estado",
      setupAndSupport: "Configuración y soporte",
      show: "Mostrar",
      smartVendingClinical: "Smart Vending Clinical",
      start: "Inicio",
      state: "Estado",
      storage: "Almacenamiento",
      summary: "Resumen",
      time: "Tiempo",
      transaction: "Transacción",
      tray: "Bandeja",
      total: "Total",
      totalCash: "Total efectivo",
      type: "Tipo",
      unassign: "Desasignar",
      units: "unidades",
      update: "Actualizar",
      updatedBy: "Actualizado por",
      updateDate: "Fecha de actualización",
      upload: "Cargar",
      uploading: "Cargando",
      uploadImage: "Cargar imagen",
      uploadImageTooltip: "Cargar imagen del producto que se está añadiendo",
      validate: "Validar",
      validation: "Validación",
      value: "Valor",
      webService: "Servicio web",
      withdrawal: "Retiro",
      yes: "Sí",
    },
    machine: {
      addTray: "Añadir bandeja",
      addWingTooltip: "Agregue al menos la configuración de un módulo",
      assign: "Asignar",
      assignContract: "Asignar máquina a cliente indirecto",
      assignedMachine: "Máquina asignada",
      assignUsersToVendingMachineTooltip: "Asignar usuarios a la máquina",
      configPassword: "Contraseña de configuración",
      configurationParameters: "Parámetros de configuración",
      connectionStatus: "Conexión",
      connectionStatusTooltip: "Estado de conectividad de la máquina con Noacloud",
      contract: "Contrato",
      contractTooltip: "Define la asociación entre la máquina y el cliente indirecto",
      contractDate: "Fecha de contrato",
      deleteMachineEventlogs: "Borrar registros de eventos de máquina",
      disableMachineError: "Se presentó un error durante la ejecución, no se pudo deshabilitar la máquina.",
      disableMachineMsg: "Ingrese el serial de la máquina para validar",
      enableRangeDates: "Habilitar rango de fechas",
      recoveryMachine: "Generar registros para la recuperación de la máquina",
      machineClean: "Limpiar registros generados en las pruebas de maquina",
      machineCleanContentModal:
        "¿Está seguro que desea eliminar los registros relacionados a las dispensaciones de la máquina? El proceso de limpieza guardará un resumen de los registros eliminados y la acción realizada sobre los registros actualizados. Para configurar ingrese el serial de máquina y seleccione la opción OK.",
      machineCleanTooltipButton:
        "Este proceso elimina los registros relacionados a las dispensaciones en el sistema de información. Se puede elegir la fecha inicial o el rango de tiempo desde donde se desean eliminar registros. En los registros de nube se llevan a cero las cantidades de productos y la cantidad de dinero disponible en los medios de pago. Esta acción requiere validacion mediante el serial de maquina.",
      machineRecoveryLogsContentModal:
        "¿Está seguro que desea generar los registros para la recuperación de la máquina? Para continuar ingrese el serial de máquina válido y seleccione la opción OK",
      machiveRecoveryInfo: "Información principal de la última recuperación de registros de la máquina",
      machineData: "Generar datos para máquina",
      machineSerial: "Serial de máquina",
      cleanLogsButton: "Limpiar registros de prueba",
      machineRecoveryTooltipButton:
        "Este proceso genera los registros requeridos para tener una máquina en modo de configuración inicial, esta acción requiere validación mediante el serial de máquina",
      invalidDateTimeInformation: {
        title: "Error con los campos de fecha",
        errorStartDateGreaterByEndDate: "La fecha de inicio no puede ser mayor que la fecha de finalización",
        invalidEndDate: "La fecha de finalización no fue ingresada correctamente",
        invalidStartDate: "La fecha de inicio no fue ingresada correctamente",
      },
      serialError: {
        title: "Serial erróneo",
        content: "El serial ingresado no hace referencia al serial de máquina",
      },
      invalidVersion: {
        title: "Versión de firmware no seleccionada",
        content: "Seleccione una versión para continuar con el proceso",
      },
      disableMachineTooltip: "Deshabilitar una máquina incluyendo todos sus registros asociados (configuración, periféricos, usuarios, contrato, planograma, etc).",
      disableMachineWarningMsg:
        "Esta opción permite deshabilitar una máquina con todos los registros asociados a ella (configuración, periféricos, usuarios, contrato, planograma, etc). Continúe bajo su responsabilidad.",
      disableTray: "Deshabilitar bandeja",
      filterByTooltip:
        "De acuerdo a la opción seleccionada, permite consultar el listado de máquinas que no han sido asignadas a un cliente o que se encuentran en proceso de configuración inicial",
      financeAuditingTooltip: "Relación de la auditoría financiera de la máquina, para seguimiento del saldo de efectivo y movimientos de dinero (depósito o retiro)",
      firmwareVersion: "Versión de firmware",
      firmwareVersionTooltip: "Versiones de firmware disponibles para configuración de máquinas",
      initialSetup: "Configuración inicial",
      initialSetupInfo:
        "En el proceso de configuración inicial la máquina requerirá que se ingrese una contraseña, ésta puede ser ingresada leyendo el siguiente código QR con un lector de códigos de barras 2D, si no hay un lector disponible se puede ingresar de forma manual usando la pantalla táctil de la máquina ",
      isWorkingProperly: "Funciona correctamente",
      latitude: "Latitud",
      listContractMachines: "Listar sólo máquinas con contrato",
      listContractMachinesTooltip: "Muestra sólo las máquinas que no tienen una asociación con un cliente indirecto",
      listNoContractMachines: "Listar sólo máquinas sin contrato",
      listNoContractMachinesTooltip: "Muestra sólo las máquinas que no tienen aún una asociación con un cliente indirecto",
      listNoConfiguredMachines: "Listar sólo máquinas sin configurar",
      listNoConfiguredMachinesTooltip: "Muestra sólo las máquinas que aún no han sido configuradas",
      locker: "Locker",
      lockerDimensions: "Dimensiones del locker",
      lockers: "Lockers",
      lockerConfiguration: "Configuración del locker",
      lockerConfigurationTooltip: "Configuración física del locker",
      lockerType: "Tipo de locker",
      LockerLoanTransactionInformation: "Información de alquiler de locker",
      lockerModality: "Modalidad de locker",
      lockerModalities: "Modalidades de locker",
      lockerModalitiesLocalization: {
        sell: "Venta",
        delivery: "Entrega",
        loan: "Alquiler de locker",
        borrow: "Préstamo de producto",
        fixed: "Asignación fija",
        free: "Libre",
        send: "Envío",
        empty: "Vacío",
        newSlot: "Nuevo slot",
        reserved: "Reservado",
        controlModule: "Módulo de control",
      },
      lockerModalitiesAssigned: "Modalidades de locker asignadas",
      lockerModalitiesAvailable: "Modalidades de locker disponibles",
      lockerModalitiesAssignedMessage: "Por favor asigne por lo menos una modalidad al locker.",
      lockerModalitiesAvailableMessage: "Todas las modalidades han sido seleccionadas.",
      lockerSlotPriceBySize: {
        lockerLoanPriceConfig: "Config. de precio de alquiler de locker",
        lockerLoanPriceConfigTooltip: " Permite configurar los precios de alquiler de locker de acuerdo al tamaño de los slots",
        lockerLoanPriceConfigByScDependency: "¿Desea aplicar esta configuración para todos los lockers que pertenecen a este cliente directo?",
        lockerLoanPriceConfigByScDependencyTooltip: "Si no se selecciona esta casilla, la configuración aplicará únicamente para la máquina seleccionada.",
      },
      lockerWarningVendingWing: "La máquina locker stand-alone no puede contener módulos de vending",
      longitude: "Longitud",
      machine: "Máquina",
      machineConfigured: "Configurada",
      machineContractCreation: "La nueva máquina estará asociada al cliente indirecto seleccionado. ¿Desea continuar?",
      machineDefination: "Definición de máquina",
      machineDetails: "Detalles de máquina",
      machineGeneration: "Generación de máquina",
      machineGenerationTooltip: "Generaciones de máquinas disponibles para la búsqueda o proceso de configuración",
      machineInformation: "Información de la máquina",
      machineNoConfigured: "No configurada",
      machineNoContractCreation: "La nueva máquina no tendrá contrato con ningún cliente indirecto. El contrato podrá ser asignado posteriormente. ¿Desea continuar?",
      machines: "Máquinas",
      vmEventListLoading: "Este proceso puede tardar un poco ...",
      machinesTooltip: "Dispositivos inteligentes para venta de productos, préstamos o alquiler de espacios, entrega de paquetes, etc, interconectados con el sistema Noacloud",
      machinesFilterTooltip: "Listado de máquinas disponibles de acuerdo a los criterios de la búsqueda",
      machineTooltip: "Máquinas asociadas al cliente indirecto.",
      maxNumSlots: "Núm. máximo de slots",
      maxNumTrays: "Núm. máximo de bandejas",
      model: "Modelo",
      modelTooltip: "Modelo del tipo de máquina. Por favor revise el catálogo para ver opciones disponibles",
      nameTooltip: "Nombre para asignar a la máquina",
      newMachine: "Nueva máquina",
      newMachineTooltip:
        "Permite la creación de una nueva máquina. Si se selecciona un cliente indirecto, se creará automáticamente un contrato entre la máquina y el cliente. De lo contrario, la máquina es creada sin contrato y se le podrá agregar posteriormente.",
      noahubSerial: "Identificador único de control",
      noahubSerialTooltip:
        "El identificador único de control corresponde al serial o clave que identifica cada máquina para permitir la conexión y el intercambio de información con el sistema de información. En las máquinas de primera generación es asociado a la tarjeta Noahub y en las de segunda generación es asociado al computador embebido",
      noSoSelected: "No se ha seleccionado un cliente directo",
      noSoSelectedMsg: "Por favor seleccione un cliente directo para asignarle la máquina",
      noVMSelected: "No se ha seleccionado una Vending Machine",
      noVMSelectedMsg: "Por favor seleccione una Vending Machine",
      password: "Contraseña",
      serialNumber: "Número de serie",
      serialNumberTooltip: "Identificador, número de serie o rótulo asignado por Noatec a cada máquina",
      setup: "Configuración",
      setupTooltip:
        "Acceso a información y configuración de la máquina, incluyendo: Información general, Auditoría financiera, Variables de configuración, Dispositivos periféricos.",
      slotGroup: "Grupo de posiciones",
      slotGroupToolTip: "Conjunto de posiciones asociados a una tarjeta de motores",
      slotGroupErrorDeleteing: "Error eliminando, la agrupación de slots ya se encuentra asignada",
      statusReportDateTooltip: "Fecha en la que la máquina reporto el último estado",
      trayQuantity: "Cantidad de bandejas",
      trayQuantityTooltip: "Cantidad de bandejas habilitadas para el módulo seleccionado.",
      recordsDeletedByTable: "Registros eliminados por tabla",
      recordsUpdatedByTable: "Registros actualizados por tabla",
      regsToSync: "Registros por sincronizar",
      regsToDownload: "Registros por descargar",
      regsToDownloadTooltip: "Registros por descargar a la máquina",
      regstToUpload: "Registros por subir",
      regstToUploadTooltip: "Registros faltantes por subir desde la última conexión de la máquina",
      unconfigured: "Sin configurar",
      userByVendingMachine: "Asignación de usuarios a Vending Machines",
      vendingMachine: "Vending Machine",
      vendingMachines: "Vending Machines",
      vendingMachinesTooltip: "Vending Machines asociadas al cliente indirecto",
      vendingMachineDetails: "Detalles Vending Machine",
      vendingMachineDetailsTooltip: "Mayor información respecto a la Vending Machine seleccionada",
      vmFirmware: "Firmware",
      vmSetup: "Configuración de la máquina",
      vmSetupTooltip: "Parámetros de configuración de la Vending Machine",
      vmSetupDetails: "Detalles de configuración de Vending Machine",
      vmTrayNumber: "Número de bandejas",
      vmUsers: "Usuarios por Vending Machine",
      configuration: {
        configurationInformation: "Información de configuración",
        configurationParameters: "Parámetros de configuración",
        financeAuditing: "Auditoría financiera",
        generalInformation: "Información general",
        synchronization: "Sincronización",
        peripheralDevices: "Dispositivos periféricos",
        wingsInfo: "Información de los módulos",
      },
      wing: "Módulo",
      wingAddition: "Agregar módulo",
      wingsConfig: "Configuración de módulos",
      wingsConfigured: "Módulos a configurar",
      wingDisabledError: "El módulo tiene asociado un planograma y por lo tanto aún no puede ser eliminado",
      wingInvalidParameter: "Por favor asigne por lo menos un módulo a la máquina.",
      wingNumber: "Número de módulo",
      wingParameterization: "Parametrización del módulo",
      wingTooltip: "Sección física de una máquina que puede ser del tipo Locker o Máquina dispensadora",
      wingType: "Tipo de módulo",
      changeFriendlyName: "Cambio de nombre para la máquina",
      changeFriendlyNameContents: "Para realizar el cambio, ingrese el nombre nuevo en el campo.",
      ErrorChangeFriendlyNameContents: "No se puede superar los caracteres establecidos",
      infoTextFriendlyNameContents: "Este proceso solo cambia el nombre que identifica la máquina, no cambia el cliente directo asociado.",
    },
    machineInventory: {
      amountAvailable: "Cantidad disponible",
      availability: "Disponibilidad",
      inventary: "Inventario",
      lessThan20: "Menor a 20%",
      between21and40: "Entre 21% y 40%",
      between41and70: "Entre 41% y 70%",
      greaterThan71: "Mayor a 71%",
      lockerInventory: "Inventario del locker",
      lockerInventoryTooltip: "Productos presentes en el locker",
      lockerStateTooltip:
        "MATCH: Producto esperado y producto encontrado coinciden; EMPTY BUT PRODUCT: Locker indicaba vacío pero se encontró producto; PRODUCT BUT EMPTY: Locker indicaba que había producto pero se encontró vacío; PRODUCT BUT PRODUCT: Locker indicaba que había un producto pero encontró otro producto; ERROR: La auditoría del locker no se pudo realizar; PROVISIONING: Locker fue aprovisionado.",
      machineInventoryReport: "Reporte de inventario de máquina",
      productAuditOrProvisioned: "Producto auditado / aprovisionado",
      productAuditOrProvisionedTooltip: "Producto esperado al momento de la auditoría / Producto que fue aprovisionado",
      productFound: "Producto encontrado",
      productFoundTooltip: "Producto encontrado en el locker al hacer la auditoría",
      productInventary: "Inventario por producto",
      productInventaryTooltip: "Cantidad de producto presente en la Vending Machine",
      slotInformation: "Información del slot",
      slotInventory: "Inventario por posición",
      totalAmount: "Cantidad máxima",
      vendingMachineInventory: "Inventario de máquina",
      vendingMachineInventoryTooltip: "Inventario de la cantidad de productos que se encuentran en la Vending Machine",
      vendingMachineNotInventory: "Vending Machine sin inventario",
    },
    motorsControllerCommandStatus: {
      doorIsAlreadyOpen: "El actuador ya está abierto",
      inProgress: "Hay un comando en ejecución",
      motorAtHomeOrDoorClosed: "El motor está en la posición de inicio / El actuador está cerrado",
      motorCommandStatus: "Estado reportado por el motor",
      motorHomeTimeout: "Se agotó el tiempo de espera antes de detectar la posición de inicio del motor",
      motorHomeUnexpected: "La posición de inicio del motor fue detectada antes de tiempo",
      motorNegativeRailFailure: "Se detectó falla de riel negativo en el motor",
      motorNotPresent: "Motor no detectado",
      motorOutOfHomeOrDoorOpen: "El motor no está en la posición de inicio / El actuador está abierto",
      motorOvercurrent: "Sobrecorriente detectada en motor",
      motorPositiveRailFailure: "Se detectó falla de riel positivo en el motor",
      motorUndercurrent: "Subcorriente detectada en motor",
      ok: "El motor/actuador operó correctamente",
      reset: "El comando de reinicio fue servido",
      timeoutParameterError: "Ocurrió un error en el parámetro de tiempo de espera",
      unknownCommand: "Se detectó un comando desconocido",
    },
    paymentTypes: {
      barcode: "Cédula",
      cash: "Efectivo",
      cashless: "Tarjeta débito/crédito",
      fingerprint: "Huella dáctilar",
      qr: "QR Credibanco",
      rfid: "RFID",
      token: "Token",
      httpServer: "Http server",
      nequi: "Nequi",
    },
    peripheralDevice: {
      addPeripheralDevice: "Agregar periférico",
      addPeripheralDeviceTooltip: "Agregar periférico para asignar a la Vending Machine",
      alternativeSerial: "Serial alternativo",
      assignPeripheralDeviceTooltip: "Asignar el dispositivo periférico a la máquina seleccionada",
      assignPeripheralDevice: "Asignar dispositivo periférico",
      assignPeripheralDeviceWarn: "¿Está seguro que desea habilitar la asignación del dispositivo periférico a esta máquina?",
      changePeripheralStatusWarn: "Cambiar estado de asignación del dispositivo periférico a",
      listPeripheralDevices: "Listar periféricos",
      listPeripheralDevicesTooltip: "Periféricos disponibles para asignar a la Vending Machine",
      mpn: "Serial del fabricante",
      noAvailableNoahub: "No se encuentran dispositivos Noahub disponibles",
      noAvailableNoahubMsg: "Por favor verifique que exista al menos un Noahub para la creación de la máquina.",
      noPeripheralDeviceModelSelected: "No se ha seleccionado un modelo de periférico",
      noPeripheralDeviceModelSelectedMsg: "Por favor seleccione un modelo en el cual se asignará el periférico",
      peripheralAlreadyAssignedToVmMsg: "La máquina ya tiene un dispositivo periférico del tipo seleccionado",
      billAcceptorOrReciclerAlreadyAssignedToVmMsg: "La máquina puede tener o un aceptador de billetes o un reciclador de billetes asignado a la vez",
      peripheralAlreadyAssignedToWingMsg: "El Ala ya tiene un dispositivo periférico del tipo seleccionado",
      peripheralDevice: "Dispositivo periférico",
      peripheralDeviceType: "Tipo de periférico",
      peripheralDeviceModel: "Modelo de periférico",
      peripheralDeviceSerialNumber: "Serial",
      peripheralDeviceAlternativeSerialNumber: "Serial alternativo",
      peripheralDeviceTypeReference: "Referencia del tipo de periférico",
      peripheralDeviceModelManufacturer: "Fabricante",
      peripheralDeviceTypeName: "Nombre del tipo de periférico",
      peripheralFilterTooltip: "Filtrar periféricos dependiendo de su asignación y estado.",
      peripheralDeviceDeleteWarn: "¿Está seguro que desea deshabilitar el dispositivo periférico?",
      peripheralAndVmPeripheralDeleteWarn: "Este dispositivo periférico está asignado a una máquina. ¿Está seguro que desea deshabilitarlo y remover su asignación a la máquina?",
      unassignPeripheralDevice: "Desasignar dispositivo periférico",
      unassignPeripheralDeviceWarn: "¿Está seguro que desea desasignar este dispositivo de la máquina?",
      unassignPeripheralDeviceTooltip: "Desasigna el dispositivo periférico de la máquina actual",
      missingPeripheralsinVm: "Periféricos faltantes en máquina",
      missingPeripheralsinwing: "Faltan periféricos en el módulo",
      MissingperipheralsinSlotGroup: "Periféricos faltantes en slot group",
      noAlternativeSerial: "Sin serial alternativo",
      peripheralFirstGeneration: "Primera",
      peripheralSecondGeneration: "Segunda",
      peripheralAllGeneration: "Todas ",
      peripheralTooltipByGeneration: "Modelo de periférico según el tipo de generación de máquina",
      peripheralTooltipByGenerationAddPeripheralDevice:
        "Clasificación de periféricos dependiendo el tipo de generación de máquina, al elegir la  opción deseada se habilitarán los modelos de periféricos correspondientes a la generación que soporta cada máquina",
      peripheralFilterTooltipType: "Clasificación de periféricos disponibles para las máquinas según su tipo",
      peripheralFilterTooltipModel: "Clasificación de periféricos disponibles según su referencia",
      peripheralEdition: "Edición de periférico",
      unassingnNecWarning:
        'Importante: Si después de realizar el proceso de revisión a la máquina se concluye que el NEC está defectuoso dar clic en el botón "Aceptar" para desasignarlo. Tener en cuenta que si se desasigna, se perderán los registros de la máquina que no hayan sido sincronizados al Noacloud',
    },
    permissionType: {
      permissionType: "Tipo de permiso",
      permissionTypeTooltip: "Nivel acceso que los usuarios tendrán en el sistema",
    },
    planogram: {
      addSoProductSlotType: "Agrega una nueva configuración para el producto seleccionado",
      connectionLeft: "Conexión izquierda",
      connectionRight: "Conexión derecha",
      defaultSlotPosition: "Columna por defecto",
      defaultSlotPositionTooltip: "Columnas en las cuales debe ubicarse el producto",
      defaultTrayPosition: "Bandeja por defecto",
      defaultTrayPositionTooltip: "Bandejas en las cuales debe ubicarse el producto",
      harness: "Arnés",
      harnessConnection: "Conexión del arnés",
      harnessConnections: "Conexiones del arnés",
      harnessConnectionBusy: "Conexión del arnés ocupada por el slot",
      harnessConnectionsEquals: "Conexiones del arnés iguales",
      hasHelixAligner: "Tiene alineador de espiral",
      hasProductAligner: "Tiene alineador de producto",
      hasSlidingBase: "Tiene base deslizadora",
      helix: "Espiral",
      helixTooltip:
        "Tipo de espiral definido como: Diámetro-Espacios-Sentido de giro. Si la configuración del motor es de dos motores o doble, se asume que tiene espirales con ambos sentidos de giro.",
      helixDiameter: "Diámetro del espiral",
      helixSpaces: "Espacios del espiral",
      leftHarnessConnection: "Conexión izquierda del arnés",
      leftHarnessConnectionBusy: "Conexión izquierda del arnés ocupada",
      leftHarnessConnectionRequires: "Conexión izquierda del arnés requerida",
      invalidSlot: "Posición inválida",
      motor: "Motor",
      motorConfig: "Config. motor",
      motorConfigTooltip: "Define la configuración de motores requerida para expulsar el producto",
      motorTooltip:
        "Referencia de motor. El sentido de giro del motor es el opuesto al del espiral. Si la configuración del motor es de dos motores o doble, se asume que tiene motores con ambos sentidos de giro.",
      motorType: {
        double: "Doble",
        single: "Sencillo",
        twoMotors: "Dos motores",
      },
      noWingPlanogram: "El planograma del módulo aún no ha sido configurado.",
      ocuppedSlotsNotDeleted: "Slots asignados, no pueden ser borrados",
      planogram: "Planograma",
      planogramBusy: "El planograma no se puede eliminar, aún tiene productos en ella",
      planogramConfiguration: "Configuración de planogramas",
      planogramConfigurationToolTip: "Define la configuración de la planimetría de las Vending Machine",
      planogramReview: "Resumen del planograma",
      planograms: "Planogramas",
      productAligner: "Alineador",
      productAlignerTooltip: "Suplemento en forma de varilla que se ubica a un costado del slot. Se usa para alinear productos que no se estabilizan debido a su forma.",
      removePlanogramContent: "¿Desea eliminar permanentemente el planograma de este módulo?",
      rigthHarnessConnection: "Conexión derecha del arnés",
      rigthHarnessConnectionBusy: "Conexión derecha del arnés ocupada",
      rigthHarnessConnectionRequires: "Conexión derecha del arnés requerida",
      size: "Tamaño",
      sizes: {
        double: "Doble",
        oneAndAHalf: "Uno y medio",
        single: "Sencillo",
        triple: "Triple",
        twoAndAHalf: "Dos y medio",
      },
      sizeTooltip: "Tamaño que ocupa el producto en la bandeja",
      slidingBase: "Deslizador",
      slidingBaseTooltip: "Suplemento que se ubica en la parte inferior del slot. Se usa para ajustar productos pequeños en el espiral.",
      slot: "Slot",
      slotConfiguration: "Configuración del slot",
      slotDimension: "Dimensión del slot",
      slotDimensionTooltip: "Dimensión del slot más pequeño que se puede configurar",
      slotNumber: "Número de slot",
      slotNumberBusy: "Número de slot ocupado",
      slotNumberRequired: "Slot number requerido",
      slotPositions: {
        careless: "Cualquiera",
        left: "Primera y segunda",
        middle: "Medio",
        mostLeft: "Primera",
        mostRight: "Última",
        right: "Última y penúltima",
      },
      trayPositions: {
        bottom: "Última y penúltima",
        careless: "Cualquiera",
        middle: "Medio",
        mostBottom: "Última",
        mostTop: "Primera",
        top: "Primera y segunda",
      },
      updateVmProductSlot: "Editar Planograma",
      updateVmProductSlotTooltip: "Agregar o remover productos del planograma",
      vmSlotPosition: "Posición",
      vmTrayBusy: "La bandeja no se puede eliminar, aún tiene productos en ella",
    },
    privilege: {
      privilege: "Privilegio",
      privileges: "Privilegios",
    },
    privilegeByRole: {
      assignedPrivileges: "Privilegios asignados",
      assignationLevel: "Nivel de asignación",
      assignationLevelTooltip: "Nivel de asignación del rol (ejecutivo o cliente)",
      availablePrivileges: "Privilegios disponibles",
      createRoleAndAssigPrivileges: "Crear rol y asignar privilegios",
      createRoleAndAssigPrivilegesTooltip: "Crear un nuevo rol y asignar privilegios",
      editRoleAndAssigPrivileges: "Editar rol y asignar privilegios",
      editRoleAndAssigPrivilegesTooltip: "Editar un rol y asignar privilegios",
      isBasicRole: "Es básico",
      privilegeByRole: "Privilegios por rol",
      privilegeByRoleList: "Lista de privilegios por rol",
      privilegeByRoleTooltip: "Creación de rol y asignación de privilegios a éste",
      rolesAndPrivileges: "Roles y privilegios",
      roleType: "Tipo de rol",
      roleTypeTooltip: "Lista los roles según su naturaleza: todos, rol por defecto, rol creado",
    },
    product: {
      addProducts: "Agregar productos",
      addSoProductTooltip: "Agregar un nuevo producto al cliente directo seleccionado",
      assignedProducts: "Productos asignados",
      assignProductsToClient: "Asignar productos al cliente",
      assignProductsToClientTooltip: "Asignar productos disponibles para el cliente seleccionado",
      availableProducts: "Productos disponibles",
      availableProductsTooltip: "Cantidad total de productos disponibles en el módulo seleccionado.",
      availableSoProductSlotType: "Configuraciones disponibles",
      currency: "Moneda",
      currencyTooltip: "Tipo de moneda del precio del producto",
      deleteSoProductSlotTypeWarn: "Recuerde que al eliminar una configuración de producto puede afectar los planogramas ya existentes. ¿Desea eliminarlo?",
      deleteSoProductWarn: "Recuerde que al eliminar un producto base se eliminará para todos los clientes que lo tengan asignado. ¿Desea eliminarlo?",
      depth: "Profundidad [cm]",
      editScProduct: "Editar información del producto del cliente",
      editScProductTooltip: "Permite modificar información del cliente asociada a los productos asignados.",
      editSoProduct: "Editar producto base",
      editSoProductTooltip: "Permite modificar información del producto base",
      editSoProductWarn: "Recuerde que al editar un producto base se verá reflejado en todos los clientes que lo tengan asignado. ¿Desea editarlo?",
      editSoProductSlotType: "Editar Configuración de Producto",
      editSoProductSlotTypeWarn: "Recuerde que al editar una configuración de producto puede afectar los planogramas ya existentes. ¿Desea editarlo?",
      erpCode: "Código ERP",
      erpCodeTooltip: "Código de producto asignado en el sistema ERP",
      height: "Alto [cm]",
      individualConfigBySelectedSoProduct: "Configuración individual por producto base seleccionado",
      isRequired: " es requerido.",
      manufacturingCode: "Código de manufactura",
      minAgeRequired: "Edad requerida",
      minAgeRequiredTooltip: "Mínima edad requerida para dispensación del producto en años",
      newSoPorduct: "Nuevo producto base",
      newSoProductSlotType: "Nueva configuración de producto",
      noDetails: "Este producto no tiene detalles",
      noProductSelected: "No se ha seleccionado un producto",
      noProductSelectedMsg: "Por favor seleccione un producto para asignarle una configuración",
      noProductSelectedMsg2: "Seleccione almenos un producto para realizar la asignación",
      pressAddButton: "Presione el botón Agregar para añadir uno",
      preTaxCost: "Costo de lista",
      preTaxPrice: "Precio de lista",
      preTaxPriceAlternative: "Antes de impuesto",
      preTaxPriceErrorMsg1: "Al menos uno de los productos no tiene precio antes de impuesto asignado",
      price: "Precio con impuesto",
      priceInformation: "Información de precios",
      priceTooltip: "Precio con impuesto incluido",
      product: "Producto",
      productDescription: "Descripción del producto",
      productInformation: "Información del producto",
      productQuantity: "Cantidad de productos",
      productQuantityTooltip: "Cantidad de referencias de productos disponibles para el módulo seleccionado.",
      productName: "Nombre del producto",
      productNotFound: "No se encuentran productos asociados el cliente",
      products: "Productos",
      productsAssignment: "Asignación de productos",
      productSelection: "Selección de productos",
      productsReview: "Resumen productos",
      productParameterization: "Parametrización de productos",
      scCurrencyCodeErrorMsg1: "Al menos uno de los productos no tiene tipo de moneda asignado",
      scCurrencyCodeErrorMsg2: "Al menos hay un producto con precio asignado, sin especificar el tipo de moneda",
      scCurrencyCodeErrorMsg3: "Está intentando asignar el tipo de moneda al menos a un producto sin especificar el precio antes de impuesto",
      scCurrencyCodeErrorMsg4: "El producto que está intentando editar tiene asignado un tipo de modeda que no es válido. Cambie el valor e intente guardar nuevamente",
      scPreTaxPrice: "Precio antes de impuesto",
      scProductNameTooltip: "Nombre del producto asociado por el cliente. Si el cliente no asocia un nombre se asume el nombre del producto base",
      scProductCode: "Código del producto",
      scProductCodeToltip: "Código del producto asignado por el cliente",
      soProductAssignmentToVmProductGroup: "Asignación de productos base al grupo de producto",
      scPreTaxPriceTooltip: "Precio negociado antes de impuesto",
      scProductInfo: "Productos del cliente",
      scProductsInfoTooltip: "Productos asignados al cliente",
      scProductInfoReport: "Reporte del producto del cliente indirect",
      scProductInfoTooltip: "Productos del cliente",
      scTaxValue: "Impuesto producto cliente indirecto",
      scTaxValueErrorMsg1: "Al menos uno de los productos no tiene impuesto asignado",
      scTaxValueErrorMsg2: "Al menos hay un producto con precio asignado, sin especificar el impuesto a aplicar",
      scTaxValueErrorMsg3: "Está intentando asignar el valor de impuesto al menos a un producto sin especificar el precio antes de impuesto",
      scTaxValueTooltip: "Valor del impuesto como porcentaje del precio",
      selectTaxValue: "Seleccione el valor del impuesto",
      selectTaxValueTooltip: "Valor impuesto porcentual aplicado a los productos",
      soPreTaxCost: "Costo de lista",
      soPreTaxPrice: "Precio antes de impuesto",
      soPreTaxPriceTooltip: "Precio de lista del producto antes de aplicar impuesto",
      soProduct: "Productos base",
      soProductReport: "Reporte productos base",
      soProductNameTooltip: "Nombre del producto base",
      soProducts: "Productos base",
      soProductSlotType: "Configuración de slot por producto",
      soProductSlotTypeTooltip: "Configuración de la ubicación del producto en la máquina.",
      soProductTypeTooltip: "Seleccione si el producto es para vending, locker o ambos",
      soProductTypeTooltip1: "Producto asignado a vending, locker o ambos",
      soProductTooltip: "Producto base asociado al cliente directo",
      soProductType: "Tipo de producto",
      soTaxValue: "Impuesto producto cliente directo",
      soTaxValueTooltip: "Valor del impuesto como porcentaje del precio",
      summaryProducts: "Resumen productos",
      tax: "Impuesto",
      taxValue: "Impuesto",
      taxAmount: "Valor impuesto",
      unassignedProductToScDispRule: "Productos sin asociar a la regla de dispensación",
      viewProductImage: "Ver imagen del producto",
      width: "Ancho [cm]",
      weight: "Peso [g]",
    },
    myAccount: {
      myAccount: "Mi cuenta",
      creditInfo: "Información de crédito",
      noCreditInfoText: "Usted no tiene crédito asignado.",
      dispensationRulesInfo: "Información de regla de dispensación",
      noDispensationRulesInfoText: "Usted no tiene reglas de dispensación asignadas",
      transactionsInfo: "Información de transacciones",
      noTransactionsInfo: "No se encuentran registros para el tipo de módulo y fechas seleccionadas.",
      noTransactionsInfo2: "No se encuentran registros de transacciones.",
    },
    reports: {
      report: "Reporte",
      reports: "Reportes",
      userEnrollmentInfo: "Información de enrolamiento",
      userEnrollmentInfoTooltip: "Usuarios que han sido previamente asignados a una máquina según un tipo de enrolamiento (registro de huella, tarjeta NFC, etc).",
    },
    Roles: {
      apiUser: "Usuario API",
      basicSystemRole: "Rol básico del sistema",
      billingAttendant: "Asistente financiero",
      buyer: "Comprador",
      cashAuditor: "Auditor",
      customSystemRole: "Rol personalizado del sistema",
      enroller: "Enrolador",
      Executive: "Ejecutivo",
      ExecutiveLogistic: "Ejecutivo logístico",
      humanResources: "Recursos humanos",
      lockerBuyer: "Comprador Locker",
      lockerProvisioner: "Aprovisionador Locker",
      logisticAttendant: "Asistente logístico",
      logisticManager: "Director logístico",
      noaLogisticAttendant: "Asistente logístico Noatec",
      noaLogisticManager: "Director logísitico Noatec",
      noaProductionAttendant: "Asistente producción Noatec",
      noaProductionManager: "Director producción Noatec",
      noaSupportAttendant: "Asistente soporte Noatec",
      noaSupportManager: "Director soporte Noatec",
      noaSysAdmin: "Administrador del sistema Noatec",
      noaSysSuperuser: "Super usuario del sistema Noatec",
      noaUserTest: "Usuario de prueba Noatec",
      pickingAttendant: "Asistente logístico",
      provisioner: "Aprovisionador",
      role: "Rol",
      roleList: "Lista de roles",
      reportManager: "Generador de reportes",
      scrole: "Rol de cliente directo",
      scSystemAdmin: "Administrador del sistema",
      sorole: "Rol de cliente indirecto",
      serviceStaff: "Servicio técnico",
      sysRol: "Rol del sistema",
      sysRolTooltip: "Agrupación de privilegios para realizar acciones en Noacloud",
      systemBuyer: "Comprador del sistema",
      vendingManager: "Director comercial",
      vmAdmin: "Administrador Vending Machine",
      vmBuyer: "Comprador Vending",
      vmCashAuditor: "Auditor Vending Machine",
      vmProvisioner: "Aprovisionador Vending",
      vmRol: "Rol de la máquina",
      vmRolTooltip: "Agrupación de privilegios para realizar acciones en la Vending Machine",
      vmSuperuser: "Super usuario Vending Machine",
    },
    scDependency: {
      addScDependency: "Agregar cliente indirecto",
      addScDependencyTooltip: "Agregar un nuevo cliente indirecto al cliente directo seleccionado",
      businessConstruct: "Estructuras empresariales",
      businessConstructTooltip: "Estructuras empresariales del cliente indirecto (departamentos, cargos y centros de costos)",
      configurationOption: "Tipo de componente a configurar:",
      configurationOptionToolTip: "Define el tipo de información asociada a los clientes indirecto por configurar: Cargos, departamentos o áreas y centros de costo.",
      deleteScDependencyWarn: "Recuerde que sólo se pueden eliminar clientes que no tengan usuarios, máquinas o roles asignados. ¿Desea continuar?",
      editScDependency: "Editar cliente indirecto",
      editScDependencyTooltip: "Editar la información del cliente directo seleccionado.",
      editScDependencyWarn: "¿Está seguro que desea editar este cliente indirecto?",
      indirectClient: "Cliente indirecto",
      indirectClients: "Clientes indirectos",
      indirectClientTooltip:
        "Empresa asociada a un cliente directo, que hacen uso de los dispositivos inteligentes para venta de productos, alquiler o préstamo de espacios, entrega de paquetes, etc.",
      initialConfiguration: "Configuración inicial",
      initialConfigurationToolTip: "Define la configuración inicial de la información asociada a los clientes indirecto: Cargos, departamentos o áreas y centros de costos.",
      indirectClientStructureSetup: "Configuración de estructura organizacional",
      indirectClientStructureSetupTooltip: "Define la estructura organizacional de los clientes indirectos: Perfiles de usuario, departamentos y centros de costo.",
      isProvisioningAllowed: "Autorización para aprovisionar",
      isVendingAllowed: "Autorización para dispensar",
      nit: "NIT",
      nitTooltip: "Número de Identificación Tributaria ",
      noDependencySelected: "No se ha seleccionado un cliente",
      noDependencySelectedMsg: "Por favor seleccione un cliente en el cual se asignará el usuario",
      scDependencyExists: "El cliente indirecto ya existe para el cliente directo seleccionado.",
      scDependencyNameToolTip: "Nombre del cliente indirecto",
      scDependencyNotExists: "El cliente indirecto no existe para el cliente directo seleccionado.",
      scDependencyType: {
        stockCustomer: "Cliente indirecto",
        scHeadquarter: "SC HEADQUARTER",
        scSubHeadquarter: "SC SUB HEADQUARTER",
      },
      scDependencyTypeTooltip: "Tipo de cliente indirecto",
      structureCategory: "Categoría",
      structureCategoryTooltip: "Define la información asociada a la estructura organizacional del cliente indirecto: Cargos, departamentos o áreas y centros de costo",
      validation: "Validación NIT",
      validationTooltip: "Validar que el cliente indirecto no haya sido creado previamente para el cliente directo seleccionado.",
    },
    scCostCenter: {
      addScCostCenter: "Agrega un nuevo centro de costo",
      costCenter: "Centro de costos",
      costCenterTooltip: "Centro de costo del cliente indirecto",
      costCenters: "Centros de costo",
      editScCostCenter: "Editar centro de costo",
      editScCostCenterTooltip: "Edición del centro de costo perteneciente al cliente indirecto",
      newScCostCenter: "Nuevo centro de costo",
      newScCostCenterToolTip: "Definición del nuevo centro de costo",
      scCostCenterCodeToolTip: "Código del centro de costo",
      scCostCenterDescriptionToolTip: "Información complementaria del centro de costo",
      scCostCenterNameToolTip: "Nombre del centro de costo",
      scCostCenterReport: "Reporte de centros de costos",
    },
    scDepartment: {
      addScDepartment: "Agregar un nuevo departamento",
      department: "Departamento",
      departmentTooltip: "Departamento del cliente indirecto",
      departments: "Departamentos",
      editScDepartment: "Editar departamento o área",
      editScDepartmentTooltip: "Edición del departamento o área perteneciente al cliente indirecto",
      newScDepartment: "Nuevo departamento",
      newScDepartmentToolTip: "Definición del nuevo departamento o área",
      scDepartmentDescriptionToolTip: "Información complementaria del departamento o área",
      scDepartmentInvalidError: "La información del departamento es inválida. Por favor revise que los campos obligatorios hayan sido ingresados correctamente.",
      scDepartmentNameToolTip: "Nombre del departamento o área",
      scDepartmentReport: "Reporte de departamentos",
    },
    scEmployeeInformation: {
      deleteScEmployeeInformationWarn:
        "Si el registro que está intentando eliminar se encuentra asignado a uno o varios usuarios del cliente , éste no podrá ser eliminado. ¿Desea continuar?",
      editScEmployeeInformationWarn: "Recuerde que al editar este registro se verán aplicados los cambios en las asignaciones a usuarios del cliente existentes. ¿Desea editarlo?",
      scEmployeeInformation: "Información del empleado",
    },
    scUserProfile: {
      addScUserProfile: "Agrega un nuevo cargo",
      editScUserProfile: "Editar cargo",
      editScUserProfileTooltip: "Edición del cargo perteneciente al cliente indirecto",
      newScUserProfile: "Nuevo cargo",
      newScUserProfileToolTip: "Definición del nuevo cargo",
      scUserProfileDescriptionToolTip: "Información complementaria del cargo",
      scUserProfileNameToolTip: "Nombre del cargo",
      userProfile: "Cargo",
      userProfiles: "Cargos",
      userProfileTooltip: "Cargos del cliente indirecto",
      scUserProfileReport: "Reporte de cargos",
    },
    soDependency: {
      directClient: "Cliente directo",
      directClientTooltip:
        "Empresa cliente directo de Noatec SAS, que adquiere, contrata o hace uso de los dispositivos inteligentes para venta de productos (dueño del inventario), alquiler o préstamo de espacios, entrega de paquetes, etc.",
      directClientProvisionerTooltip: "Ente encargado de realizar el aprovisionamiento de la máquina",
      createStockStatement: "Crear bodega",
      soDependencyTypeDefinition: "Tipo de cliente directo",
      soDependencyTypeDefinitionTooltip: "Escala jerárquica del cliente directo",
      soDependencyType: {
        headquarter: "Sede",
        logisticCenter: "Centro logístico",
        provisioner: "Aprovisionador",
        stockOwner: "Ejecutivo",
      },
      noDirectClientSelected: "No se ha seleccionado un cliente directo",
      noDirectClientSelectedMsg: "Por favor seleccione un cliente directo antes de asignar la configuración",
    },
    smartVendingClinical: {
      addLocationInformation: "Agregar un área de hospitalización",
      addLocationInformationTooltip: "Agregar un área de hospitalización con su información básica",
      addPatientInformation: "Agregar información del paciente",
      addPatientInformationTooltip: "Agregar un paciente con su información básica",
      admission: "Ingreso",
      availableLocationsOnly: "Se muestran únicamente las localizaciones disponibles y la localización actual del paciente",
      admissionDate: "Fecha de admisión",
      building: "Edificio",
      clinical: "Clinical",
      editPatientInformation: "Editar información del paciente",
      editPatientInformationTooltip: "Editar la información básica del paciente",
      egressDate: "Fecha de egreso",
      errorGetitngLocations: "Error obteniendo localizaciones",
      errorGettingLocationsMessage: "Se produjo un error obteniendo la información sobre localizaciones",
      floor: "Piso",
      locations: "Localizaciones",
      location: "Ubicación",
      locations: "Área de hospitalización",
      locationTootip: "Comprende la ubicación final del paciente (habitación y cama)",
      locationsTootip: "Comprende las camas disponibles en el hospital para la atención y cuidado de los pacientes",
      editLocationInformation: "Editar área de hospitalización",
      editLocationInformationTooltip: "Editar un área de hospitalización con su información básica",
      editPatientInformation: "Editar información del paciente",
      editPatientInformationTooltip: "Editar la información básica del paciente",
      importPatientsBash: "Importación masiva de pacientes",
      importPatientsBashTooltip: "Importación masiva de pacientes mediante archivo csv",
      medicalHistory: "Historia clínica",
      patientInformation: "Información del paciente",
      patientsInformation: "Información de los pacientes",
      patientsInformationTooltip: "Listado de información de los pacientes",
      patientName: "Nombre del paciente",
      patientLastName: "Apellido del paciente",
      patientIdentityCardNumber: "Identificación del paciente",
      patientSaveCondition: "Debe ingresar al menos un campo entre número de identificación, historia clínica o ingreso para guardar la información",
      Patients: "Pacientes",
      removeLocationTooltip: "Liberar localización",
      removeLocationWarn: "¿Desea liberar esta localización y dejar el paciente seleccionado sin asignación?",
      sector: "Sector",
    },
    timeUnits: {
      day: "día",
      days: "días",
      hour: "hora",
      hours: "horas",
      minute: "minuto",
      minutes: "minutos",
      month: "mes",
      months: "meses",
      week: "semana",
      weeks: "semanas",
      year: "año",
      years: "años",
    },
    transaction: {
      adjustedBy: "Ajuste realizado por",
      adjustmentDate: "Fecha de ajuste",
      adjustmentTransaction: "Ajuste de existencias faltantes",
      adjustmentType: "Tipo de ajuste",
      adjustmentTypeTooltip: "Tipo de ajuste para las cantidades faltantes",
      adjustmentClic: "Dar clic aquí para adicionar el ajuste parametrizado",
      adjustmentSelection: "Seleccione un tipo de ajuste",
      adjustmentSuccessful: "Ajuste de las cantidades faltantes exitoso",
      adjustmentTypes: {
        doubleDispensation: "Doble dispensación",
        notDetected: "No detectado",
        badProvisioning: "Mal aprovisionamiento",
        overstockAmendment: "Exceso de existencias",
        productChange: "Cambio de producto",
        missingProduct: "Falta de producto",
        overstockCancel: "Overstock cancel",
      },
      approvalCode: "Aprobación",
      discardTransaction: "Descartar productos faltantes",
      discardTransactionTooltip:
        "Descartar productos faltantes asociados a una transacción del tipo Understock no genera una dispensación exitosa en el reporte de dispensaciones, sin embargo permite realizar una trazabilidad al tipo de ajuste",
      dispensationOk: "Dispensaciones exitosas",
      dispensationFail: "Dispensaciones fallidas",
      dispensationRefunded: "Reembolsos de pagos tipo cashless",
      dispensationCancelled: "Transacciones canceladas",
      errorMsj: {
        errorMsj1: "Se debe seleccionar un tipo de ajuste",
        errorMsj2: "Ya se ha alcanzado el total de ajustes requeridos",
        errorMsj3: "La cantidad seleccionada es mayor que los ajustes requeridos",
        errorMsj4: "La cantidad seleccionada es menor que los ajustes requeridos",
        errorMsj5: "Ajuste de las cantidades faltantes no realizado",
        errorMsj6: "No se han agregado ajustes por registrar",
        errorMsj7: "Se debe ingresar una cantidad de unidades por ajustar",
      },
      errorMsjId: "Id no ingresado en el campo habilitado. Ingrese un identificador para la transacción que desea ajustar",
      errorMsjIdisNot: "No se encontró en la base de datos la transacción con id",
      errorMsjIdisNotAdjusment: "Ya fue ajustada la transacción con id",
      adjustmentReports: "Reporte de ajustes",
      bringPreviousTransactions: "Traer transacciones anteriores",
      bringNextTransactions: "Traer transacciones posteriores",
      dispensation: "Dispensación",
      dispensations: "Dispensaciones",
      dispensationAdjustment: "Ajuste de dispensaciones",
      exportReportTooltip: "Por favor seleccione un solo tipo de módulo para exportar.",
      failedDispensationAdjustment: "Ajuste de dispensaciones fallidas",
      failedDispensationAdjustmentTooltip: "Permite validar y ajustar dispensaciones fallidas",
      failedTransactions: "Transacciones fallidas",
      getTransactionsToolTip: "Obtener los registros asociados a la parametrización de la consulta.",
      justificationSelection: "Seleccione una justificación para ajustar una transacción negativa",
      justification: "Justificación",
      lockerDeliveryTransactions: "Transacciones de la entrega de producto",
      lockerLoanTransactions: "Transacciones durante el alquiler de locker",
      maxValueToAdjust: "Máxima cantidadad de unidades por ajustar",
      moreTransactions: "Ver las transacciones anteriores y posteriores realizadas en la máquina",
      overStockedTransaction: "Transacciones positivas",
      overstockAmendmentAmountTooltip: "Unidades ajustadas por exceso de existencias",
      paymentType: "Método de pago",
      paymentTypeTooltip: "Método de pago de la dispensación (cash, card, QR)",
      provisioningDetails: "Detalles del proceso de aprovisionamiento",
      provisioningId: "Id de aprovisionamiento",
      provisioningProcessDate: "Fecha del proceso de aprovisionamiento",
      provisioningProcessId: "Identificador del proceso de aprovisionamiento",
      provisioningProcessIdTooltip: "Cada proceso de aprovisionamiento tiene asignado un identificador único para agrupar las transacciones asociadas a dicho proceso",
      productTransactions: "Transacciones de producto",
      provisioningReport: "Reporte de aprovisionamiento",
      provisioningReportTooltip: "Permite obtener un reporte detallado del proceso de aprovisionamiento de una Vending Machine",
      provisioningReview: "Resumen de aprovisionamiento",
      purchaseTransaction: "Resumen financiero",
      recipient: "Destinatario",
      registerDoubleDispensing: "Registrar doble dispensación",
      registerDoubleDispensingTooltip: "Dar clic aquí para adicionar el ajuste por doble dispensación",
      searchUnderStockTransactionsByIdTooltip: "Buscar por el identificador de la transacción que desea ajustar",
      selectTransaction: "Seleccione una transacción para cargar la imagen",
      summaryAdjust: "Resumen de ajustes por registrar",
      stockedAmountTooltip: "Unidades aprovisionadas",
      totalAmount: "Total de unidades a ajustar",
      transactionInformation: "Información de la transacción",
      transactions: "Transacciones",
      transactionsAdjustment: "Ajuste de transacciones",
      transactionFlow: "Flujo de transacciones",
      transactionId: "ID",
      transactionIdentifier: "Identificador de la transacción",
      transactionNotFound: "No encontrada",
      transactionReport: "Reporte de transacción",
      transactionsReport: "Reporte de transacciones",
      transactionsReportTooltip: "Permite obtener un reporte detallado de las dispensaciones realizadadas",
      transactionStatus: "Estado de la transacción",
      transactionsToAdjust: "Transacciones por ajustar",
      transactionType: "Tipo de transacción",
      transactionTypeTooltip: "Tipo de transacción realizada",
      transactionsFail: "Transacciones fallidas",
      uncollectedDays: "Días sin retiro",
      uncollectedProducts: "Productos de locker no recogidos",
      uncollectedProductsTooltip: "Productos que fueron depositados en el locker para su entrega y aún no recogidos",
      understockAmendmentAmountTooltip: "Unidades ajustadas por faltante de existencias",
      underStockedTransaction: "Transacciones negativas",
      underStockedTransactionAdjustment: "Ajuste de transacciones negativas",
      underStockedTransactionAdjustmentTooltip: "Permite ajustar las cantidades faltantes registradas durante el proceso de aprovisionamiento",
      unitsToAdjust: "Unidades por ajustar",
      userTransacions: "Transacciones de usuario",
      info: {
        info1: "Transacción que indica aprovisionamiento de la máquina.",
        info2: "Ajuste negativo realizado durante el proceso de aprovisionamiento. Se encontró físicamente una cantidad de producto menor a la indicada por la máquina.",
        info3: "Ajuste positivo realizado durante el proceso de aprovisionamiento. Se encontró físicamente una cantidad de producto mayor a la indicada por la máquina.",
        info4: "El producto fue dispensado y entregado al usuario exitosamente.",
        info5: "El producto fue dispensado exitosamente pero se requirió un cuarto de giro del motor.",
        info6: "El producto no fue dispensado.",
      },
    },
    transactionMonitor: {
      avg: "Prom",
      analysisIntervalParameters: "Parámetros del intervalo de análisis",
      performance: "Desempeño",
      performanceTooltip: "Comparación contra las ventas promedio por máquina",
      productPriceTooltip: "Valor unitario antes de aplicar impuesto. Se muestra el valor del producto en la última transacción realizada",
      rangeAnalysis: "Análisis por rango",
      rangeAnalysisTooltip: "Permite seleccionar un rango de unidades de tiempo para el análisis de los datos",
      salesValue: "Valor del total de las ventas antes de impuesto",
      timeUnit: "Unidad de tiempo",
      timeUnitTooltip: "Unidad de tiempo para evaluar los datos",
      topProduct: "Producto top",
      topVM: "Máquina top",
      totalTransactions: "Total transacciones",
      transactionMonitor: "Monitor de dispensaciones",
      transactionMonitorTooltip:
        "Visualiza las dispensaciones realizadas filtradas por intervalos de tiempo. También muestra las máquinas que más han vendido y los productos de mayor consumo",
      machineSalesAvg: "Ventas promedio por máquina",
      machinesVending: "Máquinas dispensando",
    },
    transactionType: {
      stocked: "Aprovisionado",
      stockedReview: "Total aprovisionados",
      dispensed: "Dispensado",
      understockAmendment: "Ajuste negativo",
      understockAmendmentReview: "Total ajustes negativos",
      overstockAmendment: "Ajuste positivo",
      overstockAmendmentReview: "Total ajustes positivos",
      dispensationFailed: "Dispensación fallida",
      dispensationFailedCashless: "Dispensación rechazada - Cashless",
      dispensationFailedNequi: "Dispensación rechazada - Nequi",
      dispensationCancelledByUser: "Dispensación cancelada por el usuario",
      dispensationCancelledForInactivity: "Dispensación cancelada por inactividad",
      dispensedWithLastPush: "Dispensación por cuarto de giro",
      dispensationAdjustment: "Ajuste de dispensación",
      foundAndState: "Hallazgo / Estado",
      lockerProductSell: "Venta de productos",
      lockerProductProvisioning: "Aprovisionamiento de productos",
      lockerProductBorrow: "Alquiler de productos",
      lockerProductReturn: "Retorno de productos",
      lockerProductDelivery: "Entrega de paquetes",
      lockerProductDeliveryStart: "Entrega de paquetes",
      lockerProductDeliveryFinish: "Recogida de paquetes",
      lockerProductSend: "Envío de paquetes",
      lockerProductPickUp: "Retiro de paquetes",
      lockerLoan: "Alquiler/Préstamo de espacios",
      lockerLoanOpen: "Apertura de espacios",
      lockerReturn: "Retorno de espacios",
      lockerOpen: "Apertura de locker",
      lockerAudit: "Auditoría de locker",
      lockerAuditMatch: "Auditoría locker concuerda",
      lockerAuditEmptyButProduct: "Auditoría por espacios libres (reportado con producto)",
      lockerAuditProductButEmpty: "Auditoría de espacios con producto (reportado como vacío)",
      lockerAuditProductButProduct: "Auditoría de espacios con producto erróneo",
      lockerAuditError: "Auditoría de locker en error",
      lockerReservedDeliveryStart: "Entrega de paquetes con reserva",
    },
    transactionTypeLocalization: {
      stocked: "Aprovisionado",
      dispensed: "Dispensado",
      understockAmendment: "Ajuste negativo",
      overstockAmendment: "Ajuste positivo",
      dispensationFailed: "Dispensación fallida",
      dispensedWithLastPush: "Dispensación por cuarto de giro",
      dispensationAdjustment: "Ajuste de dispensación",
    },
    users: {
      addClientUsers: "Agregar usuario del cliente",
      addClientUsersTooltip: "Agregar usuario perteneciente al cliente indirecto",
      addExecutiveUsers: "Agregar usuario corporativo",
      addExecutiveUsersTooltip: "Agregar usuario perteneciente al cliente directo",
      anonymousUser: "Compra anónima",
      clientUsers: "Usuarios del cliente",
      creditByUser: {
        creditByUser: "Crédito por usuario",
        creditByUserReport: "Reporte de crédito por usuario",
        creditAssignation: "Asignar crédito a usuarios",
        creditAssignationTooltip: "Crédito que podrán utilizar los usuarios como método de pago para realizar dispensaciones",
        creditAssigned: "Crédito asignado",
        creditAvailable: "Crédito disponible",
        creditSelection: "Selección de crédito",
        editCreditByUser: "Editar crédito a usuario",
      },
      dateOfBirth: "Fecha de nacimiento",
      documentNumber: "Número de documento",
      documentType: "Tipo de documento",
      editClientUsers: "Editar usuario del cliente",
      editClientUsersTooltip: "Editar usuario perteneciente al cliente indirecto",
      editExecutiveUsers: "Editar usuario corporativo",
      editExecutiveUsersTooltip: "Editar usuario perteneciente al cliente directo",
      executiveUsers: "Usuarios corporativos",
      firstName: "Primer nombre",
      gender: "Género",
      id: "CC",
      identityCardNumber: "Número de identificación",
      identityCardType: "Documento de identificación",
      identityCardType2: {
        birthRecord: "Registro de nacimiento",
        citizenCard: "Documento de identidad",
        employeeId: "Id empleado",
        none: "Ninguna",
        passport: "Pasaporte",
      },
      importUsersBash: "Importación masiva de usuarios",
      importUsersBashTooltip: "Importación masiva de usuarios del cliente mediante archivo csv. Los usuarios ingresados tendrán por defecto el rol de compradores",
      lastName: "Apellidos",
      lastName1: "Primer apellido",
      lastName2: "Segundo apellido",
      listClientUsers: "Lista de usuarios del cliente",
      listClientUsersTooltip: "Lista de usuarios pertenecientes al cliente",
      listExecutiveUsers: "Lista de usuarios corporativos",
      listExecutiveUsersTooltip: "Lista de usuarios corporativos",
      lockerLoanExceptionByUser: {
        discountPercent: "Porcentaje de descuento",
        discountPercentTooltip: "Porcentaje de descuento que se aplica sobre el valor del alquiler",
        editLockerLoanException: "Editar excepción de alquiler de locker",
        editLockerLoanExceptionTooltip: "Modifica los parámetros del descuento de alquiler de locker para un usuario",
        exceptionSelection: "Selección de excepción",
        exceptionStartDateTooltip: "Fecha inicial del descuento",
        exceptionStartEndTooltip: "Fecha final del descuento",
        ilimitedTooltip: "Porcentaje de descuento aplica sin restricción de tiempo ni fecha límite.",
        lockerLoanException: "Descuento de alquiler de locker",
        lockerLoanExceptionByUser: "Descuento de alquiler de locker por usuario",
        lockerLoanExceptionByUserTooltip: "Descuentos en el uso de locker en modalidad de alquiler, para usuarios del cliente",
        lockerLoanExceptionByUserReport: "Reporte de descuentos de alquiler de locker por usuarios",
        lockerLoanExceptionAssignation: "Asignación de descuento de alquiler de locker a usuarios",
        lockerLoanExceptionAssignationTooltip: "Configuración de los descuentos en el uso de locker en modalidad de alquiler, para usuarios del cliente",
        timeTooltip: "Periodo de tiempo sobre el cual se aplica el porcentaje de descuento",
        vendingMachineSelectionTooltip:
          "Seleccione la máquina a la cual desea aplicar el descuento. Si selecciona Todos, el descuento se aplicará para todas las máquinas del cliente indirecto.",
      },
      middleName: "Segundo nombre",
      name: "Nombre",
      phone1: "Teléfono 1",
      phone2: "Teléfono 2",
      provisionerOnly: "Mostrar sólo personal aprovisionador",
      user: "Usuario",
      userExists: "El usuario ya existe en el sistema",
      userInformation: "Información del usuario",
      username: "Nombre de usuario",
      userNotExists: "El usuario no existe en el sistema",
      userParameters: "Parámetros del usuario",
      userSoLevel: "Nivel del cliente directo al cual se asignará el usuario",
      userType: "Tipo de usuario",
      userTypeTooltip: "Tipo de usuario que se añadirá al cliente",
      users: "Usuarios",
      usersReport: "Reporte de usuarios",
      usersSelected: "Usuarios seleccionados",
      userSelection: "Selección de usuarios",
      validateUser: "Validar usuario",
      validateUserTooltip: "Validar si el número de documento de identidad del usuario ya existe para el cliente seleccionado",
    },
    userEnrollment: {
      enrolled: "Enrolado",
      enrolledPlu: "Enrolados",
      barcodeAmount: "Cantidad de código de barras enrolados",
      barcodeUuid: "Código de barras",
      cardNumber: "Número de tarjeta",
      enrollmentDate: "Fecha de enrolamiento",
      enrollmentStatus: "Estado de enrolamiento",
      enrollmentType: "Tipo de enrolamiento",
      fpAmount: "Cantidad de huellas enroladas",
      fpImage: "Huella enrolada",
      showEnrollmentFilterTooltip: "Muestra los usuarios según su estado de enrolamiento",
      moreInfoDetailsTooltip: "Muestra más información del usuario como máquinas asociadas, roles, reglas de dispensación, detalles de enrolamiento, entre otros.",
      notEnrolled: "No enrolado",
      notEnrolledPlu: "No enrolados",
      rfidCardUid: "Códigos RFID enrolados",
    },
    UsersByVendingMachine: {
      addUsersByVendingMachine: "Asignación de usuarios a Vending Machine",
      addUsersByVendingMachineTooltip: "Asigna usuarios a su correspondiente Vending Machine",
      confirmRemoveUserFromVM:
        "¿Desea eliminar la asignación del usuario a la Vending Machine?. Recuerde que al realizar dicha acción el usuario no podrá realizar dispensaciones en la máquina.",
      UsersByVendingMachine: "Usuarios por Vending Machine",
      UsersByVendingMachineTooltip: "Muestra los usuarios asignados a una máquina",
    },
    vmByUser: {
      vmByUser: "Máquinas asignadas",
    },
    vmcashvaulttype: {
      amountReceived: "Cantidad recibida",
      amountReceivedTooltip: "Cantidad introducida a la Vending Machine (incluyendo crédito)",
      amountReturned: "Cantidad retornada",
      amountRetorunedTooltip: "Cantidad retornada por la Vending Machine",
      billBox: "Caja de billetes",
      billEscrow: "Billetero",
      billRecyclerQuantityTooltip:
        "Depósito donde se almacenan los billetes para ser retirados como ganancia en la auditoría. Esta funcionalidad sólo está disponible si físicamente el billetero la dispone y además, coincide con la denominación configurada.",
      box: "Caja",
      boxTooltip: "Compartimentos donde se depositan los billetes para devolución.",
      cashless: "Tarjeta débito/crédito",
      cashMovements: "Movimientos de efectivo en máquina",
      cashBalance: "Saldo de efectivo en máquina",
      coinBox: "Caja de monedas",
      coinContainer: "Contenedor",
      coinContainerTooltip: "Depósito donde se almacenan las monedas para ser retiradas como ganancia en la auditoría.",
      coinTubes: "Monedero",
      coinTubesTooltip: "Compartimentos donde se depositan las monedas para devolución.",
      credit: "Crédito",
      escrow: "Guardado automático",
      escrowTooltip:
        "Al marcar esta opción el billete pasará directamente a la caja, sino quedará en espera. Se recomienda que los billetes de alta denominación queden en espera para evitar el cambio por unos de menor denominación.",
      enabledTooltip: "Se refiere a cuáles billetes son aceptados.",
      highSecurity: "Alta seguridad",
      highSecurityTooltip: "Define cuáles billetes se verifican con mayor rigurosidad.",
      msgNotMovements: "No existen movimientos de efectivo (depósito o retiro) para la máquina",
      recycler: "Reciclador",
      recycleTooltip: "Asegúrese de que las denominaciones seleccionadas coinciden con las denominaciones a reciclar en la configuración física del billetero.",
      totalBillBox: "Total billetero",
      totalBillBoxTooltip: "Sumatoria entre el total de la caja y el total del reciclador",
      totalCoinBox: "Total caja de monedas",
      totalCoinBoxTooltip: "Sumatoria entre el total de los tubos y el total de la caja de monedas",
    },
    vmEvent: {
      vmEvent: "Eventos de Vending Machine",
      vmEventTooltip: "Eventos generados por las Vending Machine",
    },
    vmEventMessaging: {
      vmEventMessaging: "Mensajería de eventos",
      sendSms: "Enviar SMS",
      sendEmail: "Enviar email",
      smsPhone: "Número telefónico al cual se enviarán los SMS de alerta",
      email: "Correo electrónico al cual se enviarán las alertas",
    },
    vmProductGroup: {
      addVmProductGroup: "Agregar grupo de producto",
      addVmProductGroupTooltip: "Agregar un grupo de productos para el cliente seleccionado",
      alreadyExistsProductGroupError: "Ya existe un grupo de productos llamado: ",
      availableProductGroups: "Grupos de productos disponibles",
      configurationBySelectedVmProductGroup: "Configuración por grupo de producto seleccionado",
      deleteVmProductGroupWarn:
        "Recuerde que al eliminar un grupo de productos puede afectar las reglas de dispensación existentes y asignadas a los usuarios finales. ¿Desea eliminarlo?",
      editVmProductGroup: "Editar grupo de productos",
      editVmProductGroupTooltip: "Modificar la información del grupo de productos",
      editVmProductGroupWarn:
        "Recuerde que al editar la configuración de un grupo de productos puede afectar las reglas de dispensación existentes y asignadas a los usuarios finales. ¿Desea editarlo?",
      noVmProductGroupSelected: "No se ha seleccionado un grupo de producto",
      noVmProductGroupSelectedMsg: "Por favor seleccione un grupo de producto antes de modificar la configuración",
      soProductAssignmentToVmProductGroup: "Asignación de productos base al grupo de productos",
      soProductByVmProductGroupNotFound: "No se encuentran productos asociados al grupo de productos",
      vmProductGroup: "Grupo de producto",
      vmProductGroups: "Grupo de productos",
      vmProductGroupAssignedTooltip: "Productos base asignados al grupo de producto",
      vmProductGroupByDirectClient: "Product groups by direct client",
      vmProductGroupDescriptionToolTip: "Información complementaria del grupo de producto",
      vmProductGroupNameToolTip: "Nombre del grupo de producto",
      vmProductGroupTooltip: "Agrupación de productos base para definición de reglas dispensación",
    },
    vmProductTransaction: {
      syncingTransaction: "Información de la transacción por sincronizar",
    },
    vmServiceRequest: {
      active: "Vigente",
      endDate: "Fecha de finalización",
      expired: "Caducado",
      password: "Contraseña",
      serviceRequest: "Solicitud de servicio",
      serviceRequestTooltip: "Proceso de solicitud de credenciales para conexión remota a las máquinas",
      startDate: "Fecha de inicio",
      user: "Usuario",
    },
    vmSetup: {
      accessLevel: "Nivel de acceso",
      accessLevelTooltip: "Permite clasificar las variables de configuración de la máquina de acuerdo a su nivel de acceso",
      accessLevels: {
        basic: "Básico",
        intermediate: "Intermedio",
        advanced: "Avanzado",
        experto: "Experto",
      },
      addModuleTooltip: "Agregar un módulo a la máquina",
      category: "Categoría",
      categoryTooltip: "Permite clasificar las variables de configuración de la máquina de acuerdo a su utilidad",
      categories: {
        general: "General",
        dispensation: "Dispensación",
        localization: "Localización",
        blocking: "Bloqueo",
        synchronization: "Sincronización",
        provisioning: "Aprovisionamiento",
        enrollment: "Enrolamiento",
        cvAlgorithm: "Algoritmo CV",
        refrigeration: "Refrigeración",
        cashManagement: "Manejo de dinero",
      },
      editVmsetup: "Editar parámetro de configuración",
      editLockerTooltip: "Editar el tipo de locker y sus modalidades",
      editVmsetupWarn: "Al editar este parámetro afectará el funcionamiento de la máquina, ¿Desea continuar?",
      parameter: "Parámetro",
      newValue: "Nuevo valor",
      currentValue: "Valor actual",
      removeModuleContent: "¿Desea eliminar permanentemente el módulo seleccionado?",
      removeModuleTitle: "Eliminar módulo",
      removeModuleTooltip: "Eliminar el módulo seleccionado",
      tableName: "Nombre de la tabla",
      regsQty: "Cantidad de registros",
      oldestDate: "Fecha del registro más antiguo",
      oldestDateTooltip: "Fecha del registro más antiguo por sincronizar al Noacloud",
      oldestDateTooltip2: "Fecha del registro más antiguo por sincronizar a la máquina",
      noRegistersToSync: "Número de registros por sincronizar",
    },
  },
  // English
  en: {
    communications: {
      connectionIndicators: "Connectivity indicators",
      communications: "Communications",
      communicationsMonitor: "Communications monitor",
      communicationsMonitorTooltip: "Monitor the communications statistics of the machines by time intervals",
      metricType: "Metric type",
      metricTypeTooltip: "Select the communications metric to plot",
      requests: "Requests",
      requestsTooltip: "Requests to Noacloud",
      ping: "Ping",
      speed: "Speed",
      signal: "Signal",
      success: "Success",
      fail: "Fail",
      noResponse: "No Res",
      signalIntensity: "Signal intensity",
      traffic: "Traffic",
      successRequests: "Success requests",
      successRequestsTooltip: "Success requests to Noacloud (http 200 and 201)",
      failRequests: "Failed requests",
      failRequestsTooltip: "Failed requests to Noacloud (http different to 200 and 201). Requests are retried afterwards",
      noResponseRequests: "No response requests",
      noResponseRequestsTooltip: "No response requests to Noacloud. Requests are retried afterwards",
      connectionServiceIndex: "Connectivity service index",
      connectionServiceIndexTooltip: "Index that compares the successful requests divided by the total requests",
      uploadTraffic: "Upload traffic",
      uploadTrafficTooltip: "Upload traffic to Noacloud in kB",
      downloadTraffic: "Download traffic",
      downloadTrafficTooltip: "Download traffic from Noacloud in kB",
      download: "Down",
      upload: "Up",
      pingAverage: "Latency average",
      pingAverageTooltip: "Latency average in ms for ping test",
      uploadSpeedAverage: "Upload speed average",
      uploadSpeedAverageTooltip: "Upload speed average. Only available if setupParameter MEASURE CONNECTION SPEED is enabled",
      downloadSpeedAverage: "Download speed average",
      downloadSpeedAverageTooltip: "Download speed average. Only available if setupParameter MEASURE CONNECTION SPEED is enabled",
      signalIntensityAverage: "Signal intensity average",
      signalIntensityAverageTooltip:
        "Signal intensity average. Only available if the machine uses WiFi or the 4G modem is directly connected (No router). If the modem is a Huawei, intensity is shown as levels (0-5), otherwise is shown as dB",
      seeMetrics: "See metrics",
    },
    dependencyType: {
      dependencyType: "Dependency type",
      dependencyTypeTooltip: "Dependency type (direct or indirect)",
    },
    dispensationReport: {
      delivered: "Delivered",
      dispensations: "Dispensations",
      dispensationReports: "Dispensation reports",
      dispensationTooltip: "Dispensations made by users in Vending Machines.",
      dispensationDateTooltip: "Range of dates on which dispensations will be consulted.",
      returned: "Returned",
      transactionDate: "Transaction date",
      transactionDateTooltip: "It is the date when the machine made the transaction",
      transactionId: "Transaction Id",
      storageDate: "Storage date",
      storageDateTooltip: "It is the date when the transaction is saved in the system",
      syncDate: "Synchronization date",
      userId: "User Id",
      userImage: "User image",
      productImage: "Product image",
    },
    dispensationRule: {
      addDispensationRule: "Add dispensation rule",
      addDispensationRuleTooltip: "Add new dispensation rule for the selected indirect client.",
      addProducts: "Add products",
      addProductsTooltip: "Select products in order to add them to the dispensation rule. Only the products that had been added previously to the indirect client are shown.",
      allowedAmount: "Allowed amount",
      allowedAmountTooltip: "Allowed product amount that can be dispensed by the user",
      alreadyExistsDispensationRuleError: "Already exist a dispensation rule called: ",
      amount: "Amount",
      assignedDispensationRules: "Dispensation rules assigned to the client.",
      availableAmount: "Available amount",
      availableAmountTooltip: "Available amount to be dispensed",
      configProducts: "Set up frequency and amount",
      configProductsTooltip: "Set up the frequency of each product associated to the rule and it's allowed amount.",
      disableScDispensationRuleMsgConfirmaiton: "The user assignments to this rule will also be disabled. Do you want to continue?",
      dispensationRuleByIndirectClient: "Reglas por cliente indirecto",
      dispensationByUser: "Dispensation rules by user",
      dispensationByUserTooltip: "Dispensation rule by which the user will be able to dispensate certain products.",
      dispensationFrequency: "Dispensation frequency",
      dispensationRule: "Dispensation rule",
      dispensationRuleDescription: "Dispensation rule description",
      dispensationRuleReport: "Dispensation rules report",
      dispenationRuleDetails: "Dispensation rule details",
      dispensationRuleProducts: "Products assigned to the dispensation rule.",
      dispensationRules: "Dispensation rules",
      dispensationRuleState: "Dispensation rule state",
      dispensationRulesTooltip: "Shows the dispensation rule for the selected indirect client.",
      dispensedAmount: "Dispensed amount",
      editDispensationRule: "Edit dispensation rule",
      editDispensationRuleTooltip: "Edit dispensation rules parameters.",
      editProdAssignment: "Edit product assignment",
      editScDispensationRule: "Edit dispensation rule",
      editScDispensationRuleErrorMsg1: "Product configuration error. ",
      editScDispensationRuleErrorMsg2: "Product group configuration error. ",
      editScDispensationRuleErrorMsg3: ". Select a frequency unit.",
      editScDispensationRuleTooltip: "Set up dispensation rule parameters",
      endDate: "End date",
      endDateTooltip: "Dispensation rule expiration date",
      errorNoProducts: "You must assign at least one product to the dispensation rule.",
      errorProdConfig: "Please check the frequency set up and the product amount and try again.",
      frequency: "Frequency",
      frequencyTooltip: "How often it's allowed to dispensate",
      frequencyUnit: "Frequency unit",
      frequencyUnitTooltip: "Frequency unit indicating how often it's allowed to dispensate",
      nameTooltip: "Name that will be assigned to the dispensation rule.",
      noDispRuleSelected: "No dispensation rule has been selected.",
      noDispRuleSelectedMsg: "To edit please select a dispensation rule.",
      noEndDate: "Open-ended close date",
      noEndDateTooltip: "The dispensation rule has no close date",
      scDispensationRuleDescription: "Dispensation rule description",
      scDispensationRuleName: "Dispensation rule name",
      scDispensationRulesAvailables: "Available dispensation rules",
      scDispensationRuleState: "Dispensation rule state",
      selectedProductWarningMsg1: "It's not possible to select the product individually, it's contained in one of the selected product groups (",
      selectedProductWarningMsg2: "). Remove the assignment by group and try again.",
      selectedVmProductGroupWarningMsg1: "It's not possible to select the product group, because it contains one of the individually assigned products (",
      selectedVmProductGroupWarningMsg2: "). Remove the individual assignment and try again.",
      selectedVmProductGroupWarningMsg3: "It's not possible to select the product group. Another group that contains products in common has already been selected",
      insertProductToVmProductGroupWarning:
        "One of the products could not be included in the product group. It is possible that this product is contained in a dispensing one individually or through a product group that includes this product group. You must remove the assignment and try again",
      showOnlyDisabledDispenseRules: "Show only disabled dispensation rules",
      soProductAssignmentToDispensationRule: "Stock owner product assignation to the dispensation rule",
      startDate: "Start date",
      startDateTooltip: "Dispensation rule start date",
      startEndDateToAssign: "Parameterization of the start and end date of the dispensing rule",
      stateToolTip: "Dispensation rules enable state",
    },
    dispensationRuleByUser: {
      addDispensationRuleByUser: "Assign dispensation rules to users",
      dispensationRuleByUser: "Dispensation rule by user",
      dispensationRuleByUserTooltip: "Dispensation rules associated to a user.",
      dispensationRuleToAssign: "Dispensation rule selection",
      editDispensationRuleByUser: "Edit user's dispensation rule",
      editDispensationRuleByUserTooltip: "Edit dispensation rules associated to a user",
      selectUser: "Select user",
      dispensationRuleByUserReport: "Dispensation rule by user report",
    },
    eventType: {
      eventCategory: "Event category",
      eventQuantity: "Event quantity",
      eventType: "Event type",
      priority: "Priority",
      priorityTooltip: "Event priority range (low, medium, high)",
      eventTypeCategoryName: "Event category type",
      seeTable: "See table",
      seeTableTooltip: "See events organized chronologically as a table",
      seeChart: "See chart",
      seeChartTooltip: "See events grouped as histograms with the number of ocurrencies",
    },
    fileImport: {
      additionalFields: "Additional fields",
      dateFormat: "Date format",
      dispensationRuleUpdateOptions: "Dispensation rule replace",
      dispensationRuleUpdateOptionsTooltip:
        "Actions to execute when a user already has an enabled dispensation rule. Replace: If the rule to assign is already assigned to the user then the information of the file is skipped. Otherwise, if the rule is different, the previous rule is disabled and a new one is created with the information of the csv file. Omit: Omits the information of the dispensation rule of the file. The start date is assigned to the rule and not to users.",
      downloadFailedRegistersFile: "Failed registers file",
      downloadTemplate: "Template",
      importFinished: "Import finished",
      importRegistersCSV: "Import registers by csv file",
      invalidColPairing: "It is not possible to assign the same field twice. Please check the pairing",
      mandatoryFields: "Mandatory fields",
      pairFields: "Match the fields of the uploaded file with the required fields for the data import",
      reenableUserEnrollment: "Recover last enrollment information",
      reenableUserEnrollmentTooltip:
        "If a user is already in the system but was previously deleted, it`s enrollment information will be recovered. Otherwise this information will be omitted.",
      registersBadFormat: "The following registers do not have the required format",
      SuccessFull: "imported successfully",
      registers: "registers",
      registersTransaction: "records obtained from the query",
      registersTransactionContent: "This export process to flat csv file may take a while, to start select ok",
      registersFailed:
        "The following registers do have information not known by the system and could not be imported. Please check the fields information the failed records and try again.",
      registersOmited:
        "The following registers were omitted because they were already in the system. If you want to update their information by the one of the csv file check the option Update existing registers",
      selectFileWithFields: "Select a csv file containing the following fields separated by semicolon (;)",
      selectImportOptions: "Select the desired import options",
      updateExitingRegisters: "Update basic user info",
      updateExitingRegistersTooltip:
        "Choosing this option will update the information of the registers already present in the system with the one of the csv file. If the file contains registers previously deleted, the registers will be re-enabled and their information will be updated. Otherwise, if this option is not chosen, the csv file information for the registers already present in the system will be skipped.",
    },
    firmwareVersion: {
      additionalFile: "Additional files",
      additionalFilePlaceholder: "File name",
      addFirmwareVersionTooltip: "Form for adding new firmware versions, first and second generation machines.",
      editFirmwareVersionTooltip: "Form to edit firmware versions, first and second generation machines",
      additionalFileComment: "File description",
      deleteFirmwareVersionWarn: "Do you want to delete it?",
      descriptionInstructionsFile: "Description of the instruction file",
      descriptionLibScript: "Description of the libScript",
      descriptionNoaVmcmanagerJar: "Description of the noaVmcmanager Jar",
      descriptionNoaVmcCashlessJar: "Description of the noaVmcCashless Jar",
      descriptionNoaVmcAIDetectorJar: "Description of the noaVmcAIDetector Jar",
      descriptionNoaVmcJar: "Description of the noaVmc Jar",
      descriptionSqlScript: "Description of the sqlScript",
      descriptionToolTip: "Description of the firmware version",
      detailsTooltip: "Firmware version details",
      editFirmwareVersion: "Edit firmware version information",
      editFirmwareVersionWarn: "Do you want to edit it?",
      instructionsFile: "Instruction file",
      libScript: "LibScript",
      libScriptPlaceholder: "LibScript file name",
      noaVmc: "NoaVmc",
      noaVmcPlaceholder: "NoaVmc file name",
      noaVmcJar: "NoaVmc Jar",
      noaVmcManager: "NoaVmcManager",
      noaVmcManagerPlaceholder: "NoaVmcManager file name",
      noaVmcmanagerJar: "NoaVmcmanager Jar",
      noaVmcCashless: "NoaVmcCashless",
      noaVmcCashlessPlaceholder: "NoaVmcCashless file name",
      noaVmcCashlessJar: "NoaVmcCashless Jar",
      noaVmcAIDetector: "NoaVmcAIDetector",
      noaVmcAIDetectorPlaceholder: "NoaVmcAIDetector file name",
      noaVmcAIDetectorJar: "NoaVmcAIDetector Jar",
      sqlScript: "SqlScript",
      sqlScriptPlaceholder: "SqlScript file name",
      version: "version",
      versionTooltip: "Name of the firmware version",
      versionsLimit: "Limit",
      versionsLimitTooltip: "Indicates the number of previous versions with which the new version will be compatible",
      versionRequirements: "Version requirements",
    },
    generalResponses: {
      authError: "Authentication error",
      authErrorTooltip: "There was an error in the authentication, you will be redirected to the login",
      alreadyExistsError: "At least one of the entered values already exists in the system.",
      alreadyExistsErrorSoproduct: "The  product  code is already created in the system.",
      alreadyExistsErrorSoproductRepeat: "The product you are trying to delete belongs to the configured information of a planogram.",
      answer: "Answer",
      isError500: "Something went wrong on server.",
      pageNoFound: "Page not found",
      pageNoFoundUrl: "Please check the URL.",
      clickHere: "Click here",
      clickHereUndirection: "to be redirected to the home page.",
      connectionError: "Connection error",
      connectionErrorTooltip: "Can't connect to server",
      confirmDeleteAllMessage: "Are you sure you want to delete all the elements?",
      confirmDeleteMessage: "Are you sure you want to delete this element?",
      confirmationMessage: "Select the OK button to start or else cancel the request, modify the query parameters and try again.",
      confirmationMessageToExportTransactionReportPart1: "You are trying to export",
      confirmationMessageToExportTransactionReportPart2:
        "The system will validate if it allows you to start the direct download of the file or if it automatically sends the report to your email.",
      creationError: "Creation error",
      creationSuccess: "The information has been successfully created",
      deleteingScProdInfoVmAssigned:
        "The product is currently assigned to a Vending Machine or Locker. After doing physical provisioning, remove the product from the planogram, and then disable the product to the customer.",
      deletionSuccess: "The registry has been successfully deleted",
      deletionError: "The information has not been deleted. Try again.",
      deletionErrorTitle: "Error on deletion",
      downloadConfirmation: "The report was generated and will be downloaded by your browser",
      errorUpdating: "Updating error",
      failedTransaction: "Transaction failed",
      fileExportRequest: "Do you want to export the report?",
      generalError: "Something has happened unexpectedly. Please try again or contact Technical Support.",
      haveAsyncRequestInProcess: "You have an asynchronous product transaction report request pending or in process",
      imageError: "Error loading image",
      infoSuccessfullyRegistered: "Information has beem successfully registered",
      invalidEndDateError: "Invalid end date",
      invalidStartDateError: "Invalid start date",
      invalidEmail: "The email is required to make an async product transaction report request",
      invalidError: "The information is incorrect. Please check the mandatory field's parameters and try again",
      invalidNullFields: "Some fields is required to make an async product transaction report request",
      invalidNumberOfFields: "The number of required fields is invalid",
      invalidParameters: "Invalid parameters",
      invalidValue: "Invalid value",
      keyIsAssigned: "The record is assigned to another system element.",
      loadingViewMessage: "We are preparing the Noacloud for you ...",
      noResults: "No results",
      notificationOfAutomaticReportSubmissionTitle: "Notification of automatic report submission",
      notificationOfAutomaticReportSubmissionPart1: "The number of records to be exported is greater than the amount allowed by the system to be downloaded directly",
      notificationOfAutomaticReportSubmissionPart2: "The transaction report was automatically generated and sent to your email",
      notificationOfAutomaticReportSubmissionPart3: "Wait a moment and verify that you have received the report. Otherwise try again",
      searchInformation: "Search information",
      queryResponse: "No results associated with the selected search criteria",
      reportGenerationError: "Report generation error",
      ruleWithAssignedUsers: "The rule has assigned users. Please assign a new rule to each user before deleting it",
      saveError: "The information has not been saved. Please try again or contact technical service",
      saveError2: "The price, tax and currency type fields are required. Please complete the information and try again",
      saveError3: "The tax and currency type fields are required. Please complete the information and try again",
      saveSuccess: "The information has been successfully saved",
      serverError: "Server error",
      serviceError: "Service error",
      successfulDelete: "Successfully deleted",
      successfullCreation: "Successfully created",
      successfulTransaction: "Transaction successful",
      successfulUpdate: "Successfully updated",
      transactionReportRegister: "Do you want to export",
      transactionReportRegisterModalWarningTitle: "too many records to export",
      transactionReportRegisterModalWarningContent: "The maximum allowed is:",
      FIELD_VALUE_IS_EMPTY: "The field can not be empty.",
      updatingGeneralParameters: "Updating general parameters",
      assignedProducts: "Assigned products",
      unassignedProducts: "Unassigned products",
    },
    generalTerms: {
      accept: "Accept",
      add: "Add",
      additionalInfo: "Additional information",
      address: "Address",
      adjust: "Adjust",
      all: "All",
      age: "Age",
      dateRecovery: "effective recovery date",
      applyToAllVm: "Apply to all Vending Machines",
      assignedPl: "Assigned",
      assign: "Assign",
      assignationDate: "Assignation date",
      assignSettings: "Assign settings",
      attribute: "Attribute",
      available: "Available",
      basicInformation: "Basic information",
      byDefault: "By default",
      cancel: "Cancel",
      capacity: "Capacity",
      caution: "Caution",
      changePassword: "Change password",
      check: "Search",
      city: "City",
      code: "Code",
      codeToAssign: "Code to assign",
      column: "Column",
      columnTooltip: "Cantidad máxima de columnas del locker",
      columns: "Columns",
      companyInfo: {
        aboutUs: "About Noatec SAS",
        contactInformation: "Contact information",
        contactUs: "Contact us",
        copyRight: "All rights reserved",
        privacyPolicies: "Privacy policies",
        termnsAndConditions: "Terms and conditions",
      },
      creationDate: "Creation date",
      country: "Country",
      ciiuCode: "CIIU code",
      customer: "Customer",
      date: "Date",
      delete: "Delete",
      deleteWarn: "Are you sure to delete?",
      deleteTaskWarning: "Are you sure to delete this task?",
      denomination: "Denomination",
      dependency: "Dependency",
      deposit: "Deposit",
      description: "Description",
      details: "Details",
      disable: "Disable",
      disabledPl: "Disabled",
      disabled: "Disabled",
      doesNotApply: "does not apply",
      each: "each",
      edit: "Edit",
      elapsedTime: "Elapsed time",
      elapsedTimeTooltip: "Time elapsed since today without picking up the products",
      email: "Email address",
      emptyDescription: "Empty Description",
      enable: "Enable",
      enabled: "Enabled",
      enabledPl: "Enabled",
      end: "End",
      enterId: "Enter Id number",
      every: "Every",
      executive: "Executive",
      export: "Export",
      exportReport: "Export report",
      selectFileType: "Select file type",
      failureType: "Failure type",
      filterBy: "Filter by",
      file: "File",
      free: "Free",
      funcionalities: "Functionalities",
      general: "General",
      generalParameters: "General parameters",
      generalConfigs: "General configurations",
      home: "Home",
      ilimited: "unlimited",
      import: "Import",
      important: "Important",
      inConfiguration: "In configuration",
      information: "Information",
      informationNotAvailable: "Not available information",
      isEnabled: "Is it enabled?",
      items: "items",
      leftShort: "Left",
      level: "Level",
      loading: "Loading...",
      logout: "Logout",
      menu: "Menu",
      message: "Message",
      mobil: "Mobil",
      moreInfo: "More information",
      myAccount: "My account",
      myPersonalInformation: "My personal information",
      name: "Name",
      nameToAssign: "Name to assign",
      no: "No",
      noacloudPharma: "Noacloud Pharma",
      notAssigned: "Not assigned",
      notAssignedPl: "Not assigned",
      notAvailable: "Not available",
      of: "of",
      omit: "Omit",
      parameterization: "Parameterization",
      phone: "Phone",
      position: "Position",
      price: "Price",
      province: "Province",
      registers: "Registers",
      remove: "Remove",
      replace: "Replace",
      reportDate: "Report date",
      reportType: "Report type",
      requiredFields: "Required fields",
      reset: "Reset",
      restart: "Restart",
      result: "Result",
      return: "Go back",
      rightShort: "Right",
      row: "Row",
      rowTooltip: "Maximum number of locker rows according to the smallest slot",
      rows: "Rows",
      sales: "Sales",
      save: "Save",
      search: "Search",
      searchById: "Search by Id",
      security: "Security",
      seeMore: "See more",
      select: "Select",
      selection: "Selection",
      selectDate: "Select date",
      selectFile: "Select a file",
      selectGender: "Select a gender",
      selectLocation: "Select a location",
      selectState: "Select state",
      setupAndSupport: "Setup and support",
      smartVendingClinical: "Smart Vending Clinical",
      show: "Show",
      start: "Start",
      state: "State",
      storage: "Storage",
      summary: "Summary",
      time: "Time",
      transaction: "Transaction",
      tray: "Tray",
      total: "Total",
      totalCash: "Total Cash",
      type: "Type",
      unassign: "Unassign",
      units: "units",
      update: "Update",
      updatedBy: "Updated by",
      updateDate: "Update date",
      upload: "Upload",
      uploading: "Uploading",
      uploadImage: "Image upload",
      uploadImageTooltip: "Upload image of the product being added",
      validate: "Validate",
      validation: "Validation",
      value: "Value",
      webService: "Web service",
      withdrawal: "Withdrawal",
      yes: "Yes",
    },
    machine: {
      addTray: "Add tray",
      addWingTooltip: "Add at least one module configuration",
      assign: "Assign",
      assignContract: "Assign machine to indirect client",
      assignedMachine: "Assigned machine",
      assignUsersToVendingMachineTooltip: "Add users to vending machine",
      configPassword: "Configuration password",
      configurationParameters: "Configuration parameters",
      connectionStatus: "Connection",
      connectionStatusTooltip: "Connection status between the machine and Noacloud",
      contract: "Contract",
      contractTooltip: "Defines the association between the machine and the indirect client",
      contractDate: "Contract date",
      deleteMachineEventlogs: "Delete machine event logs",
      disableMachineError: "An error occurred during execution, the machine could not be disabled.",
      disableMachineMsg: "Generate logs for machine recovery",
      recoveryMachine: "Generate logs for machine recovery",
      machineClean: "Clear logs generated in machine tests",
      machineCleanContentModal:
        "Are you sure you want to delete the records related to machine dispensing? The cleaning process will save a summary of the deleted records and the action taken on the updated records. To configure enter the machine serial number and select the OK option.",
      machineCleanTooltipButton:
        "This process removes dispensing-related records from the cloud. You can choose the starting date or the time range from which you want to delete records. In the cloud records, the quantities of products and the amount of money available in the means of payment are reset to zero. This action requires validation by machine serial.",
      machineRecoveryLogsContentModal: "Are you sure you want to generate the logs for machine recovery? To continue enter the valid serial number and select the ok option",
      machiveRecoveryInfo: "Main information of the latest retrieval of machine logs",
      machineData: "Generate data",
      machineSerial: "Serial of machine",
      cleanLogsButton: "Clean test logs",
      machineRecoveryTooltipButton:
        "This process generates the records required to have a machine in initial configuration mode, this action requires validation through the machine serial",
      invalidDateTimeInformation: {
        title: "Error with the date fields",
        errorStartDateGreaterByEndDate: "The start date cannot be greater than the end date",
        invalidEndDate: "End date not entered correctly",
        invalidStartDate: "Start date not entered correctly",
      },
      serialError: {
        title: "failed series",
        content: "The entered serial does not refer to the machine serial",
      },
      invalidVersion: {
        title: "Firmware version not selected",
        content: "Select a version to continue with the process",
      },
      disableMachineTooltip: "Disable a machine including all its associated records (configuration, peripherals, users, contract, planogram, etc).",
      disableMachineWarningMsg:
        "This option allows disabling a machine with all the registers associated with it (configuration, peripherals, users, contract, planogram, etc). Continue at your own risk.",
      disableTray: "Disable tray",
      filterByTooltip:
        "Depending on the option selected, it allows you to consult the list of machines that have not been assigned to a client or that are in the initial configuration process",
      financeAuditingTooltip: "Report of the financial audit of the machine, to monitor the cash balance and money movements (deposit or withdrawal)",
      firmwareVersion: "Firmware version",
      firmwareVersionTooltip: "Firmware versions available for machine configuration",
      initialSetup: "Initial setup",
      initialSetupInfo:
        "In the initial configuration process the machine will require that a password be entered, it can be entered by reading the following QR code with a 2D barcode reader, if there is no reader available it can be entered manually using the touch screen of the machine ",
      isWorkingProperly: "Working properly",
      latitude: "Latitude",
      listContractMachines: "Show only machines without contract",
      listContractMachinesTooltip: "Shows only the machines that are not associated with any indirect client",
      listNoContractMachines: "Show only machines without contract",
      listNoContractMachinesTooltip: "Shows only the machines that are not associated with any indirect client",
      listNoConfiguredMachines: "Show only no configured machines",
      listNoConfiguredMachinesTooltip: "Shows only the machines that have not been set up yet.",
      locker: "Locker",
      lockerDimensions: "Locker dimensions",
      lockers: "Lockers",
      lockerConfiguration: "Locker configuration",
      lockerConfigurationTooltip: "Locker configuration appearance",
      lockerType: "Locker type",
      LockerLoanTransactionInformation: "Locker loan information",
      lockerModality: "Locker modality",
      lockerModalities: "Locker modalities",
      lockerModalitiesLocalization: {
        sell: "Sell",
        delivery: "Delivery",
        loan: "Loan",
        borrow: "Borrow",
        fixed: "Fixed",
        free: "Free",
        send: "Send",
        empty: "Empty",
        newSlot: "New slot",
        reserved: "Reserved",
        controlModule: "Control module",
      },
      lockerModalitiesAssigned: "Assigned locker modalities",
      lockerModalitiesAvailable: "Available locker modalities",
      lockerModalitiesAssignedMessage: "Please select at least one locker modality.",
      lockerModalitiesAvailableMessage: "All modalities have been selected.",
      lockerSlotPriceBySize: {
        lockerLoanPriceConfig: "Locker loan price setup",
        lockerLoanPriceConfigTooltip: "Allows to configure locker loan prices according to slot size",
        lockerLoanPriceConfigByScDependency: "Do you want to apply this configuration for all the direct client loan lockers?",
        lockerLoanPriceConfigByScDependencyTooltip: "If the checkbox is not selected, the setup will apply only for the selected machine",
      },
      lockerWarningVendingWing: "The stand-alone locker machine cannot contain any vending module",
      longitude: "Longitude",
      machine: "Machine",
      machineConfigured: "Configured",
      machineContractCreation: "The new machine will be associated with the selected indirect client. Do you want to continue?",
      machineDefination: "Machine defination",
      machineDetails: "Machine details",
      machineGeneration: "Machine generation",
      machineGenerationTooltip: "Machine generations available for search or configuration process",
      machineInformation: "Machine information",
      machineNoConfigured: "Not configured",
      machineNoContractCreation: "The new machine will not have a contract, but it could be assigned later to an indirect client. Do you want to continue?",
      machines: "Machines",
      vmEventListLoading: "This process may take a while ...",
      machinesTooltip: "Smart devices for the sale of products, loans or rental of spaces, delivery of packages, etc., interconnected with the Noacloud system",
      machinesFilterTooltip: "List of available machines according to the search criteria",
      machineTooltip: "Machines associated to indirect clients",
      maxNumSlots: "Maximum slot number",
      maxNumTrays: "Maximum tray number",
      model: "Model",
      modelTooltip: "Machine type model. Check the catalog for available options.",
      nameTooltip: "Name assigned to the machine",
      newMachine: "New machine",
      newMachineTooltip:
        "Allows the creation of a new machine. If an indirect client is selected, a contract will be automatically assigned between the machine and the indirect client. Otherwise, the machine will be created without a contract, which could be added later.",
      noahubSerial: "Unique control identifier",
      noahubSerialTooltip:
        "The unique control identifier corresponds to the serial or key that identifies each machine to allow the connection and exchange of information with the information system. In the first generation machines it is associated with the Noahub card and in the second generation it is associated with the embedded computer",
      noSoSelected: "A direct client has not been selected.",
      noSoSelectedMsg: "Please select a direct client for the machine.",
      noVMSelected: "No Vending Machine has been selected",
      noVMSelectedMsg: "Please select a Vending Machine",
      password: "Password",
      serialNumber: "Serial number",
      serialNumberTooltip: "Identifier, serial number or label assigned by Noatec to each machine",
      setup: "Setup",
      setupTooltip: "Access to information and configuration of the machine including: General information, Finance audits, Configuration variables, Peripheral devices.",
      slotGroup: "Slot group",
      slotGroupToolTip: "Slot group associated to a motors controller board",
      slotGroupErrorDeleteing: "Deleting error, slot group is already assigned",
      statusReportDateTooltip: "Date on which the machine reported the last status",
      trayQuantity: "Tray quantity",
      trayQuantityTooltip: "Tray amount available for the selected module.",
      recordsDeletedByTable: "Records delete by table",
      recordsUpdatedByTable: "Records updated by table",
      regsToSync: "Registers to sync",
      regsToDownload: "Registers to download",
      regsToDownloadTooltip: "Registers to download to the machine",
      regstToUpload: "Registers to upload",
      regstToUploadTooltip: "Registers to upload to the cloud",
      unconfigured: "Unconfigured",
      userByVendingMachine: "Assign users to Vending Machines",
      vendingMachine: "Vending Machine",
      vendingMachines: "Vending Machines",
      vendingMachinesTooltip: "Vending Machines associated with the indirect customer",
      vendingMachineDetails: "Vending Machine details",
      vendingMachineDetailsTooltip: "Shows more information about the selected Vending Machine.",
      vmFirmware: "Firmware",
      vmSetup: "Machine setup",
      vmSetupTooltip: "Vending machine setup parameters.",
      vmSetupDetails: "Vending Machine setup details",
      vmTrayNumber: "Tray number",
      vmUsers: "Users by Vending Machine",
      configuration: {
        configurationInformation: "Configuration information",
        configurationParameters: "Configuration parameters",
        financeAuditing: "Finance auditing",
        generalInformation: "General information",
        synchronization: "Synchronization",
        peripheralDevices: "Peripheral devices",
        wingsInfo: "Module information",
      },
      wing: "Module",
      wingAddition: "Add new module",
      wingsConfig: "Module configuration",
      wingsConfigured: "Modules to configure",
      wingDisabledError: "The module has a planogram associated so it can't be disabled",
      wingInvalidParameter: "Please add at least one module configuration",
      wingNumber: "Module number",
      wingParameterization: "Module parameterization",
      wingTooltip: "Physical section of a machine that can be of the Locker or Dispensing machine type",
      wingType: "Module type",
      changeFriendlyName: "Name change for the vending machine",
      changeFriendlyNameContents: "To make the change, enter the new name in the field.",
      ErrorChangeFriendlyNameContents: "Cannot exceed set characters",
      infoTextFriendlyNameContents: "This process only changes the name that identifies the machine, it does not change the associated direct client.",
    },
    machineInventory: {
      amountAvailable: "Amount available",
      availability: "Availability",
      inventary: "Inventory",
      lessThan20: "Less than 20%",
      between21and40: "Between 21% and 40%",
      between41and70: "Between 41% and 70%",
      greaterThan71: "Greater than 71%",
      lockerInventory: "Locker inventory",
      lockerInventoryTooltip: "Product inventory available in the locker",
      lockerStateTooltip:
        "MATCH: Expected product and product found matched; EMPTY BUT PRODUCT: Locker indicated empty but found a product; PRODUCT BUT EMPTY: Locker indicated there was a product but found it empty; PRODUCT BUT PRODUCT: Locker indicated there was a product but found another one; ERROR: Locker inventory auditing could not be done; PROVISIONING: Locker was provisioned.",
      machineInventoryReport: "Machine inventory report",
      productAuditOrProvisioned: "Audited/Provisioned product",
      productAuditOrProvisionedTooltip: "Expected product when audited / Product that was provisioned",
      productFound: "Product found",
      productFoundTooltip: "Product that was found when audited",
      productInventary: "Inventory per product",
      productInventaryTooltip: "Product amount available in the Vending Machine",
      slotInformation: "Slot information",
      slotInventory: "Inventory per slot",
      totalAmount: "Total amount",
      vendingMachineInventory: "Machine inventory",
      vendingMachineInventoryTooltip: "Product amount inventory available in the Vending Machine",
      vendingMachineNotInventory: "Vending Machine without inventory",
    },
    motorsControllerCommandStatus: {
      doorIsAlreadyOpen: "The actuator is already open",
      inProgress: "There is a command in execution",
      motorAtHomeOrDoorClosed: "The motor is in home position / The actuator is closed",
      motorCommandStatus: "State reported by the motor",
      motorHomeTimeout: "Timeout occurred before detecting home in motor",
      motorHomeUnexpected: "Home was detected early in motor",
      motorNegativeRailFailure: "Negative rail failure was detected in motor",
      motorNotPresent: "Motor not detected",
      motorOutOfHomeOrDoorOpen: "The motor is out of the home position / The actuator is open",
      motorOvercurrent: "Overcurrent detected in motor",
      motorPositiveRailFailure: "Positive rail failure was detected in motor",
      motorUndercurrent: "Undercurrent detected in motor",
      ok: "Motor/actuator operated correctly",
      reset: "The reset command was served",
      timeoutParameterError: "An error occurred in the timeout parameter",
      unknownCommand: "Unknown command was detected",
    },
    paymentTypes: {
      barcode: "Identity card number",
      cash: "Cash",
      cashless: "Debit/Credit Card",
      fingerprint: "Fingerprint",
      qr: "Credibanco QR",
      rfid: "RFID",
      token: "Token",
      httpServer: "Http server",
      nequi: "Nequi",
    },
    paymentTypesLocalization: {
      barcode: "BARCODE",
      cash: "CASH",
      cashless: "CASHLESS",
      fingerprint: "FINGERPRINT",
      qr: "CREDIBANCO QR",
      rfid: "RFID",
      token: "TOKEN",
    },
    peripheralDevice: {
      addPeripheralDevice: "Add peripheral device",
      addPeripheralDeviceTooltip: "Add peripheral device to assign to the Vending machine",
      alternativeSerial: "Alternative serial",
      assignPeripheralDeviceTooltip: "Assign the peripheral device to the selected machine",
      assignPeripheralDevice: "Assign peripheral device",
      assignPeripheralDeviceWarn: "¿Are you sure you want to enable the peripheral device assignation to this machine?",
      changePeripheralStatusWarn: "Change assignation status of peripheral device to",
      listPeripheralDevices: "Peripheral devices",
      listPeripheralDevicesTooltip: "Peripherals devices available to assign to the Vending Machine",
      mpn: "Manufacturer serial",
      noAvailableNoahub: "Can´t find available Noahubs",
      noAvailableNoahubMsg: "Please check there is at least one Noahub available for the machine creation.",
      noPeripheralDeviceModelSelected: "Peripheral device model not selected",
      noPeripheralDeviceModelSelectedMsg: "Please select a model in which the peripheral device will be assigned",
      peripheralAlreadyAssignedToVmMsg: "The machine already has assigned a peripheral device of the selected type",
      billAcceptorOrReciclerAlreadyAssignedToVmMsg: "The machine can have only one Bill Acceptor or one Bill Recycler assigned at the same time",
      peripheralAlreadyAssignedToWingMsg: "The Wing has already  assigned a peripheral device of the selected type",
      peripheralDevice: "Peripheral device",
      peripheralDeviceType: "Peripheral device type",
      peripheralDeviceModel: "Peripheral device model",
      peripheralDeviceSerialNumber: "Serial",
      peripheralDeviceAlternativeSerialNumber: "Alternative serial",
      peripheralDeviceTypeReference: "Peripheral device type reference",
      peripheralDeviceModelManufacturer: "Manufacturer",
      peripheralDeviceTypeName: "Peripheral device type name",
      peripheralFilterTooltip: "Filter peripherals depending on it´s status and assignation.",
      peripheralDeviceDeleteWarn: "Are you sure you want to disable the peripheral device?",
      peripheralAndVmPeripheralDeleteWarn: "This peripheral device is assigned to a machine. ¿Are you sure you want to disable it and it´s assignation?",
      unassignPeripheralDevice: "Unassign peripheral device",
      unassignPeripheralDeviceWarn: "Are you sure you want to unassign this device from the machine?",
      unassignPeripheralDeviceTooltip: "Unassign the peripheral device from the current machine.",
      missingPeripheralsinVm: "Missing peripherals in vending machine.",
      missingPeripheralsinwing: "Missing peripherals in wing.",
      MissingperipheralsinSlotGroup: "Missing peripherals in Slot Group.",
      noAlternativeSerial: "No alternative serial",
      peripheralFirstGeneration: "First",
      peripheralSecondGeneration: "Second",
      peripheralAllGeneration: "All",
      peripheralTooltipByGeneration: "Peripheral model according to the type of machine generation",
      peripheralTooltipByGenerationAddPeripheralDevice:
        "Classification of peripherals depending on the type of machine generation, when choosing the desired option, the peripheral models corresponding to the generation that each machine supports will be enabled",
      peripheralFilterTooltipType: "Classification peripherals available machines according to their type",
      peripheralFilterTooltipModel: "Classification peripherals available according to reference",
      peripheralEdition: "Peripheral edition",
      unassingnNecWarning:
        'Important: If after performing the review process to the machine it is concluded that the NEC is defective, click on the "Accept" button to unassign it. Keep in mind that if it is unassigned, the records of the machine that have not been synchronized to the Noacloud will be lost',
    },
    permissionType: {
      permissionType: "Permission type",
      permissionTypeTooltip: "Access level that users will have in the system",
    },
    planogram: {
      addSoProductSlotType: "Add a new configuration for the selected product.",
      connectionLeft: "Left connection",
      connectionRight: "Right connection",
      defaultSlotPosition: "Default slot position",
      defaultSlotPositionTooltip: "Slots in which the product may be located.",
      defaultTrayPosition: "Default tray position",
      defaultTrayPositionTooltip: "Trays in which the product may be located.",
      harness: "Harness",
      harnessConnection: "Harness connection",
      harnessConnections: "Harness connections",
      harnessConnectionBusy: "Harness connection occupied by slot",
      harnessConnectionsEquals: "Same harness connections",
      hasHelixAligner: "It has helix aligner",
      hasProductAligner: "Product aligner included",
      hasSlidingBase: "Sliding base included",
      helix: "Helix",
      helixTooltip:
        "Helix type defined as: Diameter-Spaces-(Rotational direction). If the motor configuration has two or double motors, it's assumed that it has helixes with both rotational directions.",
      helixDiameter: "Helix diameter",
      helixSpaces: "Helix spaces",
      leftHarnessConnection: "Left harness connection",
      leftHarnessConnectionBusy: "Left harness connection occupied",
      leftHarnessConnectionRequires: "Left harness connection required",
      invalidSlot: "Invalid slot",
      motor: "Motor",
      motorConfig: "Motor config.",
      motorConfigTooltip: "It defines the motor configuration required to expel the product.",
      motorTooltip:
        "Motor reference. The rotational direction is the opposite of the helix's. If the motor configuration has two or double motors, it's assumed that it has motors with both rotational directions.",
      motorType: {
        double: "Double",
        single: "Single",
        twoMotors: "Two motors",
      },
      noWingPlanogram: "The planogram for the module has not been configured yet.",
      ocuppedSlotsNotDeleted: "Assigned slots, cannot be deleted",
      planogram: "Planogram",
      planogramBusy: "The planogram cannot be deleted, it still has products in it",
      planogramConfiguration: "Planograms setup",
      planogramConfigurationToolTip: "Defines the planimetric configuration in the Vending Machine",
      planogramReview: "Planogram review",
      planograms: "Planograms",
      productAligner: "Aligner",
      productAlignerTooltip: "Supplement rod-shaped located on one side of the slot. It's used to align products that are not easily stabilized due to its shape.",
      removePlanogramContent: "Do you want to permanently remove the planogram from this module?",
      rigthHarnessConnection: "Right harness connection",
      rigthHarnessConnectionBusy: "Right harness connection occupied",
      rigthHarnessConnectionRequires: "Right harness connection required",
      size: "Size",
      sizes: {
        double: "Double",
        oneAndAHalf: "One and a half",
        single: "Single",
        triple: "Triple",
        twoAndAHalf: "Two and a half",
      },
      sizeTooltip: "Size of a product occupied in the tray.",
      slidingBase: "Deslizador",
      slidingBaseTooltip: "Supplement located on the lower part of the slot. It's used to adjust small products in the helix.",
      slot: "Slot",
      slotConfiguration: "Slot configuration",
      slotDimension: "Slot dimension",
      slotDimensionTooltip: "Smallest slot dimension that can be configured",
      slotNumber: "Slot number",
      slotNumberBusy: "Slot number busy",
      slotNumberRequired: "The slot number is required",
      slotPositions: {
        careless: "Any",
        left: "Top and second-to-top",
        middle: "Middle",
        mostLeft: "Top",
        mostRight: "Last",
        right: "Last and second-to-last",
      },
      trayPositions: {
        bottom: "Last and second-to-last",
        careless: "Any",
        middle: "Middle",
        mostBottom: "Last",
        mostTop: "Top",
        top: "Top and second-to-top",
      },
      updateVmProductSlot: "Edit planogram",
      updateVmProductSlotTooltip: "Add or remove planogram products",
      vmSlotPosition: "Slot position",
      vmTrayBusy: "The tray cannot be removed, it still has products in it",
    },
    privilege: {
      privilege: "privilege",
      privileges: "Privileges",
    },
    privilegeByRole: {
      assignedPrivileges: "Assigned privileges",
      assignationLevel: "Assignation level",
      assignationLevelTooltip: "Role assignation level (executive or client)",
      availablePrivileges: "Available privileges",
      createRoleAndAssigPrivileges: "Create role and assign privileges",
      createRoleAndAssigPrivilegesTooltip: "Create a new role and assign privileges",
      editRoleAndAssigPrivileges: "Edit role and assign privileges",
      editRoleAndAssigPrivilegesTooltip: "Edit a role and assign privileges",
      isBasicRole: "Is basic",
      privilegeByRole: "Privilege by role",
      privilegeByRoleList: "Privilege by role list",
      privilegeByRoleTooltip: "Role creation and assignment of privileges to it",
      rolesAndPrivileges: "Roles and privileges",
      roleType: "Role type",
      roleTypeTooltip: "List the roles according to their nature: all, default role, created role",
    },
    product: {
      addProducts: "Add products",
      addSoProductTooltip: "Add a new product to the selected direct client",
      assignedProducts: "Assigned products",
      assignProductsToClient: "Assign products to the client",
      assignProductsToClientTooltip: "Assign products that are available for the selected client.",
      availableProducts: "Available products",
      availableProductsTooltip: "Total available products for the selected module.",
      availableSoProductSlotType: "Available configurations",
      currency: "Currency",
      currencyTooltip: "Currency for the product's price",
      deleteSoProductSlotTypeWarn: "Remember that when a product configuration is deleted, the planograms that already exist may be affected. Do you want to delete?",
      deleteSoProductWarn: "Remember that when a Stock Owner product is deleted, it is also deleted to all the clients that have the product assigned. Do you want to delete?",
      depth: "Depth [cm]",
      editScProduct: "Edit customer product information",
      editScProductTooltip: "It allows modifying customer information associated with the assigned products.",
      editSoProduct: "Edit direct client product",
      editSoProductTooltip: "It allows edit direct client product",
      editSoProductWarn: "Remember that when a Stock Owner product is edited, the changes will affect all the clients that have the product assigned. Do you want to edit?",
      editSoProductSlotType: "Edit product configuration",
      editSoProductSlotTypeWarn: "Remember that when a product configuration is edited, the planograms that already exist may be affected. Do you want to edit?",
      erpCode: "ERP code",
      erpCodeTooltip: "Product code assigned according to the ERP (Enterprise Resource Planning) system.",
      height: "Height [cm]",
      individualConfigBySelectedSoProduct: "Individual setup by selected stock owner product",
      isRequired: " is required.",
      manufacturingCode: "Manufacturing code",
      minAgeRequired: "Age required",
      minAgeRequiredTooltip: "Minimum age required to product dispensation in years",
      newSoPorduct: "New Stock Owner product",
      newSoProductSlotType: "Product new configuration",
      noDetails: "This product has no details",
      noProductSelected: "No product has been selected",
      noProductSelectedMsg: " Please select a product in order to assign a configuration to it.",
      noProductSelectedMsg2: "Select at least one product to assign",
      pressAddButton: "Press the Add button to add a new one",
      preTaxCost: "Pre tax cost",
      preTaxPrice: "Pre tax price",
      preTaxErrorMsg1: "At least one of the products has no assigned tax",
      price: "Product price",
      priceInformation: "Price information",
      priceTooltip: "Price with tax included",
      product: "Product",
      productDescription: "Product description",
      productInformation: "Product information",
      productQuantity: "Product quantity",
      productQuantityTooltip: "Product reference amount available for the selected module.",
      productName: "Product name",
      productNotFound: "No products associated to the client were found.",
      products: "Products",
      productsAssignment: "Product assignment",
      productSelection: "Product selection",
      productsReview: "Products review",
      productParameterization: "Product parameterization",
      scCurrencyCodeErrorMsg1: "At least one of the products does not have a currency type assigned",
      scCurrencyCodeErrorMsg2: "There is at least one product with an assigned price, without specifying the type of currency",
      scCurrencyCodeErrorMsg3: "You are trying to assign the currency type to at least one product without specifying the price before tax",
      scCurrencyCodeErrorMsg4: "The product you are trying to edit has a currency code type assigned to it that is not valid. Change the value and try saving again",
      scPreTaxPrice: "Price before tax",
      scProductNameTooltip: "Name given by the client to the product. If the client does not assign a name the name of the Stock owner product is assumed",
      scProductCode: "Product code",
      scProductCodeToltip: "Product code assigned by the client",
      soProductAssignmentToVmProductGroup: "Stock owner product assignment to group product",
      scPreTaxPriceTooltip: "Negotiated price before tax",
      scProductInfo: "Client products",
      scProductsInfoTooltip: "Assigned client products",
      scProductInfoReport: "Indirect client product report",
      scProductInfoTooltip: "Products assigned to indirect client.",
      scTaxValue: "Tax value indirect client product",
      scTaxValueErrorMsg1: "At least one of the products does not have tax assigned",
      scTaxValueErrorMsg2: "There is at least one product with an assigned price, without specifying the tax to apply",
      scTaxValueErrorMsg3: "You are trying to assign the tax value to at least one product without specifying the pre-tax price",
      scTaxValueTooltip: "Tax value as a percentage of the price",
      selectTaxValue: "Select tax value",
      selectTaxValueTooltip: "Percentage tax value applied to products",
      soPreTaxCost: "List cost",
      soPreTaxPrice: "Price before tax",
      preTaxPriceAlternative: "Before tax",
      preTaxPriceErrorMsg1: "At least one of the products does not have a price before tax assigned",
      soPreTaxPriceTooltip: "Product list price before tax",
      soProduct: "Stock Owner Product",
      soProductReport: "Stock owner products report",
      soProductNameTooltip: "Stock Owner product name",
      soProducts: "Stock Owner Products",
      soProductSlotType: "Slot configuration by product",
      soProductSlotTypeTooltip: "Product configuration for the machine. Also called product data sheet.",
      soProductTypeTooltip: "Select whether the product is for vending, locker or both",
      soProductTypeTooltip1: "Product assigned to vending, locker or both",
      soProductTooltip: "Stock Owner Product associated to a direct client.",
      soProductType: "Product type",
      soTaxValue: "Tax value direct client product",
      soTaxValueTooltip: "Tax value as a percentage of the price",
      summaryProducts: "Products summary",
      tax: "Tax",
      taxValue: "Tax value",
      taxAmount: "Tax amount",
      unassignedProductToScDispRule: "Products not associated",
      viewProductImage: "View the image of the product",
      width: "Width [cm]",
      weight: "Weight [g]",
    },
    myAccount: {
      myAccount: "My account",
      creditInfo: "Credit info",
      noCreditInfoText: "You don't have any credit.",
      dispensationRulesInfo: "Dispensation rule info",
      noDispensationRulesInfoText: "You don't have any dispensation rule",
      transactionsInfo: "Transactions info",
      noTransactionsInfo: "There are not registers for the selected parameters.",
      noTransactionsInfo2: "There are not transactions registers.",
    },
    reports: {
      report: "Report",
      reports: "Reports",
      userEnrollmentInfo: "User enrollment information",
      userEnrollmentInfoTooltip: "Users who have been previously assigned to a machine according to a type of enrollment (fingerprint, NFC card, etc).",
    },
    Roles: {
      apiUser: "API user",
      basicSystemRole: "Basic system role",
      billingAttendant: "Financial attendant",
      buyer: "Buyer",
      cashAuditor: "Auditor",
      customSystemRole: "Custom system role",
      enroller: "Enroller",
      Executive: "Executive",
      ExecutiveLogistic: "Logistic executive",
      humanResources: "Human resources",
      lockerBuyer: "Locker buyer",
      lockerProvisioner: "Locker provisioner",
      logisticAttendant: "Logistic attendant",
      logisticManager: "Logistic manager",
      noaLogisticAttendant: "Noa logistic attendant",
      noaLogisticManager: "Noa logistic manager",
      noaProductionAttendant: "Noa production attendant",
      noaProductionManager: "Director Noa producción",
      noaSupportAttendant: "NMoa support attendant",
      noaSupportManager: "Noa support manager",
      noaSysAdmin: "Noa system administrator",
      noaSysSuperuser: "Noa system super user",
      noaUserTest: "Noa test user",
      pickingAttendant: "Picking logistic attendant",
      provisioner: "Provisioner",
      role: "Role",
      roleList: "Role list",
      reportManager: "Report manager",
      scrole: "Direct client role",
      scSystemAdmin: "System administrator",
      sorole: "Stock owner role",
      serviceStaff: "Technical service",
      sysRol: "System role",
      sysRolTooltip: "Group of privileges to execute actions in Noacloud.",
      systemBuyer: "System buyer",
      vendingManager: "Commercial manager",
      vmAdmin: "Vending Machine administrator",
      vmBuyer: "Buyer",
      vmCashAuditor: "Vending Machine auditor",
      vmProvisioner: "Vending provisioner",
      vmRol: "Machine role",
      vmRolTooltip: "Group of privileges to execute actions in the vending machine.",
      vmSuperuser: "Super user Vending Machine",
    },
    scDependency: {
      addScDependency: "Add indirect client",
      addScDependencyTooltip: "Add a new indirect client to the selected direct client",
      businessConstruct: "Business constructs",
      businessConstructTooltip: "Indirect client business construct (department, profile y cost center)",
      configurationOption: "Type of component to configure:",
      configurationOptionToolTip: "Defines the type of information associated with indirect client to configure: Charges, departments or areas and cost centers.",
      deleteScDependencyWarn: "Recuerde que sólo se pueden eliminar clientes que no tengan usuarios, máquinas o roles asignados. ¿Desea continuar?",
      editScDependency: "Edit indirect client",
      editScDependencyTooltip: "Editar la información del cliente directo seleccionado.",
      editScDependencyWarn: "¿Está seguro que desea editar este cliente indirecto?",
      indirectClient: "Indirect client",
      indirectClients: "Indirect clients",
      indirectClientTooltip:
        "Company associated with a direct client, which makes use of smart devices for the sale of products, rental or loan of spaces, delivery of packages, etc.",
      initialConfiguration: "Initial configuration",
      initialConfigurationToolTip: "Defines the initial configuration of the information associated with indirect client: Charges, departments or areas and cost centers.",
      indirectClientStructureSetup: "Organizational structure setup",
      indirectClientStructureSetupTooltip: "Defines the organizational structure of the indirect clients: User profiles, departments, and cost centers.",
      isProvisioningAllowed: "Is provisioning allowed?",
      isVendingAllowed: "Is vending allowed?",
      nit: "NIT",
      nitTooltip: "Número de Identificación Tributaria ",
      noDependencySelected: "A client has not been selected",
      noDependencySelectedMsg: "Please select the client to which the user will be assigned.",
      scDependencyExists: "The indirect client already exists for the selected direct client.",
      scDependencyNameToolTip: "Indirect client name",
      scDependencyNotExists: "The indirect client already exists for the selected direct client.",
      scDependencyType: {
        stockCustomer: "Indirect client",
        scHeadquarter: "SC HEADQUARTER",
        scSubHeadquarter: "SC SUB HEADQUARTER",
      },
      scDependencyTypeTooltip: "Tipo de cliente indirecto",
      structureCategory: "Category",
      structureCategoryTooltip: "Defines the information associated to the indirect client to be configured: User profiles, departments or areas, and cost centers.",
      validation: "Unique identifier validation",
      validationTooltip: "Validates that the indirect client has not been created previously for the selected direct client.",
    },
    scCostCenter: {
      addScCostCenter: "Add a new cost center",
      costCenter: "Cost center",
      costCenterTooltip: "Indirect client cost centers list",
      costCenters: "Cost centers",
      editScCostCenter: "Edit cost centers",
      editScDepartmentTooltip: "Edition of the cost centers belonging to the indirect client",
      newScCostCenter: "New cost center",
      newScCostCenterToolTip: "New cost center definition",
      scCostCenterCodeToolTip: "Cost center code",
      scCostCenterDescriptionToolTip: "Cost center additional information",
      scCostCenterNameToolTip: "Cost center name",
      scCostCenterReport: "Cost centers report",
    },
    scDepartment: {
      addScDepartment: "Add new department",
      department: "Department",
      departmentTooltip: "Indirect client departments list",
      departments: "Departments",
      editScDepartment: "Edit department",
      editScDepartmentTooltip: "Edition of the department or area belonging to the indirect client",
      newScDepartment: "New department",
      newScDepartmentToolTip: "New department or area definition",
      scDepartmentDescriptionToolTip: "Department or area additional information",
      scDepartmentInvalidError: "Department information is invalid. Please check that the required fields have been entered correctly.",
      scDepartmentNameToolTip: "Department or area name",
      scDepartmentReport: "Department report",
    },
    scEmployeeInformation: {
      deleteScEmployeeInformationWarn: "If the record you are trying to delete is assigned to one or more users of the client, it cannot be deleted. Do you wish to continue?",
      editScEmployeeInformationWarn: "Remember that by editing this record, you affect assignments to existing users. Do you want to edit it?",
      scEmployeeInformation: "Employee information",
    },
    scUserProfile: {
      addScUserProfile: "Add a new user profile",
      editScUserProfile: "Edit user profile",
      editScUserProfileTooltip: "Edition of the user profile belonging to the indirect client",
      newScUserProfile: "New user profile",
      newScUserProfileToolTip: "New user profile definition",
      scUserProfileDescriptionToolTip: "User profile additional information",
      scUserProfileNameToolTip: "User profile name",
      userProfile: "User profile",
      userProfiles: "User profiles",
      userProfileTooltip: "Indirect client user profiles list",
      scUseProfileReport: "User profile report",
    },
    soDependency: {
      directClient: "Direct client",
      directClientTooltip:
        "Direct client company of Noatec, which acquires, hires or makes use of smart devices for the sale of products (owner of the inventory), rental or loan of spaces, delivery of packages, etc.",
      directClientProvisionerTooltip: "Entity in charge of machine provisioning",
      createStockStatement: "Create stock statement",
      soDependencyTypeDefinition: "Direct client type",
      soDependencyTypeDefinitionTooltip: "Direct client hierarchical scale",
      soDependencyType: {
        headquarter: "Headquarter",
        logisticCenter: "Logistic center",
        provisioner: "Provisioner",
        stockOwner: "Executive",
      },
      noDirectClientSelected: "Direct client isn't selected",
      noDirectClientSelectedMsg: "Please select a direct client before assigning the configuration",
    },
    smartVendingClinical: {
      addLocationInformation: "Add admission area",
      addLocationInformationTooltip: "Add admission area with its basic information",
      addPatientInformation: "Add patient information",
      addPatientInformationTooltip: "Create a patient with their basic information",
      admission: "Admission",
      availableLocationsOnly: "Only available locations and patient's current location are displayed",
      admissionDate: "Admission date",
      building: "Building",
      clinical: "Clinical",
      editPatientInformation: "Edit patient information",
      editPatientInformationTooltip: "Edit a patient's basic information",
      egressDate: "Egress date",
      errorGetitngLocations: "Error getting locations",
      errorGettingLocationsMessage: "There was an error getting locations",
      floor: "Floor",
      location: "Location",
      locations: "Admission area",
      locationTootip: "It includes the patient's final location (room and bed)",
      locationsTootip: "It includes the beds available in the hospital for the care and attention of patients",
      editLocationInformation: "Edit admission area",
      editLocationInformationTooltip: "Edit admission area with its basic information",
      editPatientInformation: "Edit patient information",
      editPatientInformationTooltip: "Edit a patient's basic information",
      importPatientsBash: "Mass importation of patients",
      importPatientsBashTooltip: "Mass importation of patients via csv file",
      medicalHistory: "Medical history",
      patientInformation: "Patient information",
      patientsInformation: "Patients information",
      patientsInformationTooltip: "List of all patients information",
      patientName: "Patient'name",
      patientLastName: "Patient's last name",
      patientIdentityCardNumber: "Patient's identification",
      patientSaveCondition: "At least one field must be entered among identity card number, medical history or admission to save the information",
      Patients: "Patients",
      removeLocationTooltip: "Liberar localización",
      removeLocationWarn: "Do you wish to free this location and leave current pacient without asignation?",
      sector: "Sector",
    },
    timeUnits: {
      day: "day",
      days: "days",
      hour: "hour",
      hours: "hours",
      minute: "minute",
      minutes: "minutes",
      month: "month",
      months: "months",
      week: "week",
      weeks: "weeks",
      year: "year",
      years: "years",
    },
    transaction: {
      adjustedBy: "Adjusted by",
      adjustmentDate: "Adjustment date",
      adjustmentTransaction: "Understock adjustment",
      adjustmentType: "Adjustment Type",
      adjustmentTypeTooltip: "Adjustment type for missing quantities",
      adjustmentClic: "Click here to add the parameterized setting",
      adjustmentSelection: "Select an adjust type",
      adjustmentSuccessful: "Adjustment of missing quantities successful",
      adjustmentTypes: {
        doubleDispensation: "Double dispensation",
        notDetected: "Not detected",
        badProvisioning: "Bad provisioning",
        overstockAmendment: "Overstock Amendment",
        productChange: "Product change",
        missingProduct: "Missing product",
        overstockCancel: "Overstock cancel",
      },
      approvalCode: "Approval code",
      discardTransaction: "Discard missing products",
      discardTransactionTooltip:
        "Discarding missing products associated with a transaction of the Understock type does not generate a successful dispensing in the dispensing report, however it allows traceability to the adjustment type",
      dispensationOk: "Successful dispensations",
      dispensationFail: "Failed dispensations",
      dispensationRefunded: "Cashless payment refunds",
      dispensationCancelled: "Cancelled dispensations",
      errorMsj: {
        errorMsj1: "A type of adjustment must be selected",
        errorMsj2: "The total required adjustments have already been reached",
        errorMsj3: "The selected quantity is greater than the required settings",
        errorMsj4: "The selected quantity is less than the required settings",
        errorMsj5: "Adjustment of missing quantities not made",
        errorMsj6: "No adjustments have been added to register",
        errorMsj7: "A number of units must be entered to be adjusted",
      },
      errorMsjId: "Id not entered in the enabled field. Enter an identifier for the transaction you want to adjust",
      errorMsjIdisNot: "The transaction not found in the database id",
      errorMsjIdisNotAdjusment: "There is an adjusted transaction with id",
      adjustmentReports: "Adjustment reports",
      bringPreviousTransactions: "Get previous transactions",
      bringNextTransactions: "Get subsequent transactions",
      dispensation: "Dispensation",
      dispensations: "Dispensations",
      dispensationAdjustment: "Dispensation adjustment",
      exportReportTooltip: "Please select only one module type in order to export.",
      failedDispensationAdjustment: "Adjustment of failed dispensations",
      failedDispensationAdjustmentTooltip: "Allows validation and adjustment of failed dispensations",
      failedTransactions: "Failed transactions",
      getTransactionsToolTip: "Obtain the records associated with the parameterization of the query.",
      justificationSelection: "Select a justification to adjust a under stocked transactions",
      justification: "Justification",
      lockerDeliveryTransactions: "Product delivery transactions",
      lockerLoanTransactions: "Transactions during the locker loan",
      maxValueToAdjust: "Maximum number of units to adjust",
      moreTransactions: "View the previous and subsequent transactions made on the machine",
      overStockedTransaction: "Over stoked transaction",
      overstockAmendmentAmountTooltip: "Units adjusted for overstock",
      paymentType: "Payment type",
      paymentTypeTooltip: "Dispensation payment type (cash, card, QR)",
      provisioningDetails: "Provisioning process details",
      provisioningId: "Provisioning identifier",
      provisioningProcessDate: "Provisioning process date",
      provisioningProcessId: "Provisioning process Id",
      provisioningProcessIdTooltip: "Each provisioning process has a unique identifier assigned to group the transactions associated with that process",
      productTransactions: "Product transactions",
      provisioningReport: "Provisioning report",
      provisioningReportTooltip: "It allows to obtain a detailed report of the process of provisioning of a Vending Machine",
      provisioningReview: "Provisioning review",
      purchaseTransaction: "Financial summary",
      recipient: "Recipient",
      registerDoubleDispensing: "Register double dispensing",
      registerDoubleDispensingTooltip: "Click here to add the double dispensing adjustment",
      searchUnderStockTransactionsByIdTooltip: "Search by the transaction identifier that you want to adjust",
      selectTransaction: "Select a transaction to upload the image",
      summaryAdjust: "Summary of adjustments to register",
      stockedAmountTooltip: "Stocked units",
      totalAmount: "Total units to adjust",
      transactionInformation: "Transaction information",
      transactions: "Transactions",
      transactionsAdjustment: "Transactions adjustment",
      transactionFlow: "Transaction flow",
      transactionId: "ID",
      transactionIdentifier: "Transaction identifier",
      transactionNotFound: "Not found",
      transactionReport: "Transaction report",
      transactionsReport: "Transaction reports",
      transactionsReportTooltip: "It allows to obtain a detailed report of dispensations",
      transactionStatus: "Transaction status",
      transactionsToAdjust: "Transactions to adjust",
      transactionType: "Transaction type",
      transactionTypeTooltip: "Transaction type done",
      transactionsFail: "Failed transactions",
      uncollectedDays: "uncollected days",
      uncollectedProducts: "Uncollected locker products",
      uncollectedProductsTooltip: "Products that were deposited in the locker for delivery and not yet collected",
      understockAmendmentAmountTooltip: "Units adjusted for understock",
      underStockedTransaction: "Under stoked transaction",
      underStockedTransactionAdjustment: "Adjustment of under stocked transactions",
      underStockedTransactionAdjustmentTooltip: "Allows adjustment of missing quantities recorded during the provisioning process",
      unitsToAdjust: "Units to adjust",
      userTransacions: "User transactions",
      info: {
        info1: "Transaction indicating provisioning of the machine.",
        info2: "Negative adjustment made during the provisioning process. There is physically a smaller quantity of product than indicated by the machine.",
        info3: "Positive adjustment made during the provisioning process. There is physically a quantity of product greater than that indicated by the machine.",
        info4: "The product was dispensed and delivered to the user successfully.",
        info5: "The product was dispensed successfully but a quarter turn of the motor was required.",
        info6: "The product was not dispensed.",
      },
    },
    transactionMonitor: {
      avg: "Avg",
      analysisIntervalParameters: "Analysis interval parameters",
      performance: "Performance",
      performanceTooltip: "Comparison against average sales per machine",
      productPriceTooltip: "Unitary value before taxes. The shown value corresponds to the one of the last transaction",
      rangeAnalysis: "Range analysis",
      rangeAnalysisTooltip: "Allows to select a time units range for the data analysis",
      salesValue: "Sales total value before taxes",
      timeUnit: "Time unit",
      timeUnitTooltip: "Time unit used to evaluate the data",
      topProduct: "Top product",
      topVM: "Top machine",
      totalTransactions: "Total transactions",
      transactionMonitor: "Transactions monitor",
      transactionMonitorTooltip: "Shows the transactions filtered by time intervals. Also depicts the top selling machines and the products with the highest consumption",
      machineSalesAvg: "Machine sales average",
      machinesVending: "Machines vending",
    },
    transactionType: {
      stocked: "Stocked",
      stockedReview: "Total provisioned",
      dispensed: "Dispensed",
      understockAmendment: "Understock amendment",
      understockAmendmentReview: "Total understock amendments",
      overstockAmendment: "Overstock amendment",
      overstockAmendmentReview: "Total overstock amendments",
      dispensationFailed: "Dispensation failed",
      dispensationFailedCashless: "Dispensation refused - Cashless",
      dispensationFailedNequi: "Dispensation refused - Nequi",
      dispensationCancelledByUser: "Dispensation cancelled by user",
      dispensationCancelledForInactivity: "Dispensation cancelled for inactivity",
      dispensedWithLastPush: "Dispensed with last push",
      dispensationAdjustment: "Dispensing adjustment",
      foundAndState: "Finding / State",
      lockerProductSell: "Locker product sell",
      lockerProductProvisioning: "Locker product provisioning",
      lockerProductBorrow: "Locker product borrow",
      lockerProductReturn: "Locker product return",
      lockerProductDelivery: "Locker product delivery",
      lockerProductDeliveryStart: "Locker product delivery start",
      lockerProductDeliveryFinish: "Locker product delivery end",
      lockerProductSend: "Locker product send",
      lockerProductPickUp: "Locker product pick up",
      lockerLoan: "Locker rental/loan",
      lockerLoanOpen: "Locker loan open",
      lockerReturn: "Locker return",
      lockerOpen: "Locker open",
      lockerAudit: "Locker audit",
      lockerAuditMatch: "Locker audit match",
      lockerAuditEmptyButProduct: "Locker audit empty but product",
      lockerAuditProductButEmpty: "Locker audit product but empty",
      lockerAuditProductButProduct: "Locker audit product but product",
      lockerAuditError: "Locker audit error",
      lockerReservedDeliveryStart: "Product was assigned by the company staff",
    },
    users: {
      addClientUsers: "Add client users",
      addClientUsersTooltip: "Add users that belong to an indirect client",
      addExecutiveUsers: "Add executive users",
      addExecutiveUsersTooltip: "Add users that belong to a direct client",
      anonymousUser: "Anonymous purchase",
      clientUsers: "Client users",
      creditByUser: {
        creditByUser: "Credit by user",
        creditByUserReport: "Credit by user report",
        creditAssignation: "Adding credit to users",
        creditAssignationTooltip: "Credit that can be used by users as payment method to dispensate.",
        creditAssigned: "Credit assigned",
        creditAvailable: "Credit available",
        creditSelection: "Credit selection",
        editCreditByUser: "Edit credit by user",
      },
      dateOfBirth: "Date of birth",
      documentNumber: "Document number",
      documentType: "Document type",
      editClientUsers: "Edit client user",
      editClientUsersTooltip: "Edit user that belong to a indirect client",
      editExecutiveUsers: "Edit executive user",
      editExecutiveUsersTooltip: "Edit user that belong to a direct client",
      executiveUsers: "Executive users",
      firstName: "First name",
      gender: "Gender",
      id: "Identity card",
      identityCardNumber: "Identity card number",
      identityCardType: "Identity card type",
      identityCardType2: {
        birthRecord: "Birth record",
        citizenCard: "Citizen card",
        employeeId: "Employee Id",
        none: "None",
        passport: "Passport",
      },
      importUsersBash: "Massive importation of users",
      importUsersBashTooltip: "Mass user client import by using a csv file. The imported users will have a Buyer role by default",
      lastName: "Last names",
      lastName1: "First last name",
      lastName2: "Second last name",
      listClientUsers: "Client users list",
      listClientUsersTooltip: "Client user list",
      listExecutiveUsers: "Executive users list",
      listExecutiveUsersTooltip: "Executive user list",
      lockerLoanExceptionByUser: {
        discountPercent: "Discount percent",
        discountPercentTooltip: "Discount percent applied to loan value",
        editLockerLoanException: "Edit locker loan exception for user",
        editLockerLoanExceptionTooltip: "Modify locker loan parameters discount for a user",
        exceptionSelection: "Exception selection",
        exceptionStartDateTooltip: "Discount initial date",
        exceptionStartEndTooltip: "Discount final date",
        ilimitedTooltip: "Discount percent is applied without frequency or end date restrictions",
        lockerLoanException: "Locker loan discount",
        lockerLoanExceptionByUser: "Locker loan discount by user",
        lockerLoanExceptionByUserTooltip: "Discounts in locker loan modality, for client's users",
        lockerLoanExceptionByUserReport: "Locker loan discount by user report",
        lockerLoanExceptionAssignation: "Add locker loan discount to users",
        lockerLoanExceptionAssignationTooltip: "Discount configuration in locker loan modality, for client's users",
        timeTooltip: "Period of time over which the discount percentage is applied",
        vendingMachineSelectionTooltip:
          "Select the machine to which you want to apply the exception. If you select All, the exception will be applied to all indirect client's machines.",
      },
      middleName: "Second name",
      name: "Name",
      phone1: "Phone number 1",
      phone2: "Phone number 2",
      provisionerOnly: "Show only provisioners",
      user: "User",
      userExists: "The user already exists",
      userInformation: "User information",
      username: "Username",
      userNotExists: "The user does not exist.",
      userParameters: "User parameters",
      userSoLevel: "Direct client level to which the user will be assigned",
      userType: "User type",
      userTypeTooltip: "Type of user to be added to the client",
      users: "Users",
      usersReport: "Users report",
      usersSelected: "Users selected",
      userSelection: "User selection",
      validateUser: "Validate user",
      validateUserTooltip: "Validates if the user already exists for the direct client",
    },
    userEnrollment: {
      enrolled: "Enrolled",
      enrolledPlu: "Enrolled",
      barcodeAmount: "Enrolled barcodes amount",
      barcodeUuid: "Barcodes",
      cardNumber: "Card number",
      enrollmentDate: "Enrollment date",
      enrollmentStatus: "Enrollment status",
      enrollmentType: "Enrollment type",
      fpAmount: "Enrolled fingerprints amount",
      fpImage: "Enroled fingerprint",
      showEnrollmentFilterTooltip: "Show users according to their enrollment status",
      moreInfoDetailsTooltip: "Show more user information such as associated machines, roles, dispensing rules, enrollment details, among others.",
      notEnrolled: "Not enrolled",
      notEnrolledPlu: "Not enrolled",
      rfidCardUid: "Enrolled RFID codes",
    },
    UsersByVendingMachine: {
      addUsersByVendingMachine: "User assignment to Vending Machine",
      addUsersByVendingMachineTooltip: "Assign users to their corresponding Vending Machine",
      confirmRemoveUserFromVM:
        "Do you want to unassign the user to the Vending Machine? Remember that by performing this action the user will not be able to make dispensations in the machine.",
      UsersByVendingMachine: "Users by Vending Machine",
      UsersByVendingMachineTooltip: "Shows the users assigned to a machine.",
    },
    vmByUser: {
      vmByUser: "Assigned Vending Machines",
    },
    vmcashvaulttype: {
      amountReceived: "Amount received",
      amountReceivedTooltip: "Amount introduced to the Vending Machine (including credit)",
      amountReturned: "Amount returned",
      amountReturnedTooltip: "Amount returned by the Vending Machine",
      billBox: "Bill box",
      billEscrow: "Bill acceptor",
      billRecyclerQuantityTooltip:
        "Deposit where the bills are stored to be taken as profit in the audit. This functionality is only available if the bill acceptor physically has it and also matches the configured denomination.",
      box: "Box",
      boxTooltip: "Compartments where bills are deposited for return.",
      cashless: "Cashless",
      cashBalance: "Cash balance in machine",
      cashMovements: "Cash movements in machine",
      coinBox: "Coin box",
      coinContainer: "Container",
      coinContainerTooltip: "Deposit where the coins are stored to be taken as profit in the audit.",
      coinTubes: "Coin tubes",
      coinTubesTooltip: "Compartments where coins are deposited for return.",
      credit: "Credit",
      escrow: "Escrow",
      escrowTooltip:
        "By checking this option, the bill will go directly to the box, otherwise it will be on hold. It is recommended that high-denomination bills remain on hold to avoid exchanging for lower-denomination ones.",
      enabledTooltip: "It refers to which bills are accepted.",
      highSecurity: "High security",
      highSecurityTooltip: "Defines which bills are rigorously verified.",
      msgNotMovements: "There are no cash movements (deposit or withdrawal) for the machine",
      recycler: "Bill recycler",
      recycleTooltip: "Make sure that the selected denominations match the denominations to be recycled in the physical configuration of the bill box.",
      totalBillBox: "Total bill box",
      totalBillBoxTooltip: "Sum between the total of the box and the total of the recycler.",
      totalCoinBox: "Total coin box",
      totalCoinBoxTooltip: "Sum between the total of the tubes and the total of the coin box.",
    },
    vmEvent: {
      vmEvent: "Vending Machine events",
      vmEventTooltip: "Vending Machine generated events",
    },
    vmEventMessaging: {
      vmEventMessaging: "Messaging events",
      sendSms: "Send SMS",
      sendEmail: "Send email",
      smsPhone: "Telephone number to which the SMS alerts will be sent",
      email: "Email address to which the alerts will be sent",
    },
    vmProductGroup: {
      addVmProductGroup: "Create product group",
      addVmProductGroupTooltip: "Add a product group to the selected client",
      alreadyExistsProductGroupError: "Already exist a product group called: ",
      availableProductGroups: "Available product groups",
      configurationBySelectedVmProductGroup: "Configuration by selected product group",
      deleteVmProductGroupWarn: "Remember that eliminating a group of products may affect the existing dispensing rules and be assigned to end users. Do you want to remove it?",
      editVmProductGroup: "Edit product group",
      editVmProductGroupTooltip: "Modify product group information",
      editVmProductGroupWarn:
        "Remember that editing the configuration of a product group can affect the existing dispensing rules and be assigned to end users. Do you want to edit it?",
      noVmProductGroupSelected: "No se ha seleccionado un grupo de producto",
      noVmProductGroupSelectedMsg: "Please select a product group before modifying the configuration",
      soProductAssignmentToVmProductGroup: "Assignment of base products to the product group",
      soProductByVmProductGroupNotFound: "There aren't products associated with the product group",
      vmProductGroup: "Product group",
      vmProductGroups: "Product groups",
      vmProductGroupAssignedTooltip: "Stock owner products assigned to the product group",
      vmProductGroupByDirectClient: "Grupos de productos del cliente directo",
      vmProductGroupDescriptionToolTip: "Additional information of the product group",
      vmProductGroupNameToolTip: "Product group name",
      vmProductGroupTooltip: "Grouping of base products for definition of dispensing rules",
    },
    vmProductTransaction: {
      syncingTransaction: "Syncing transaction information",
    },
    vmServiceRequest: {
      active: "Active",
      endDate: "End date",
      expired: "Expired",

      password: "Password",
      serviceRequest: "Service request",
      serviceRequestTooltip: "Process of requesting credentials for remote connection to machines",
      startDate: "Start date",
      user: "User",
    },
    vmSetup: {
      accessLevel: "Access level",
      accessLevelTooltip: "Allows machine configuration variables to be classified according to their access level",
      accessLevels: {
        basic: "Basic",
        intermediate: "Intermediate",
        advanced: "Advanced",
        experto: "Expert",
      },
      addModuleTooltip: "Add module to machine",
      category: "Category",
      categoryTooltip: "Allows machine configuration variables to be classified according to their usefulness",
      categories: {
        general: "General",
        dispensation: "Dispensation",
        localization: "Localization",
        blocking: "Blocking",
        synchronization: "Synchronization",
        provisioning: "Provisioning",
        enrollment: "Enrollment",
        cvAlgorithm: "CV algorithm",
        refrigeration: "Refrigeration",
        cashManagement: "Cash management",
      },
      editVmsetup: "Edit setup parameter",
      editLockerTooltip: "Edit locker type and locker modalities",
      editVmsetupWarn: "Editing this parameter will affect the machine functioning, Do you want to continue?",
      parameter: "Parameter",
      newValue: "New value",
      currentValue: "Current value",
      removeModuleContent: "Are you sure you want to permanently delete the selected module?",
      removeModuleTitle: "Delete module",
      removeModuleTooltip: "Remove selected module",
      tableName: "Table name",
      regsQty: "Register quantity",
      oldestDate: "Date of the oldest register",
      oldestDateTooltip: "Date of the oldest record pending to be synchronized to the Noacloud",
      oldestDateTooltip2: "Date of the oldest record pending to be synchronized to the machines",
      noRegistersToSync: "No registers to sync where found.",
    },
  },
});

export default strings;
