// // PRODUCTION
//export const host = "https://apiprod.noacloud.co";
//export const port = "";
//export const clientId = "423156416435.app.noaclouduser.co";
//export const clientSecret = "eEStrxlQgiYPjLrtFtDGaLvc63mxXsZr";

// // TEST
export const host = "https://apitest.noacloud.co";
export const port = "";
export const clientId = "156414236435.app.noaclouduser.co";
export const clientSecret = "FtDGaLvc63mxXsZreEStrxlQgiYPjLrt";

// LOCALHOST
//export const host = "http://localhost";
//export const port = ":8080";
//export const clientId = "789423156416.app.noaclouduser.co";
//export const clientSecret = "Z4RiVUeTBSEuAReOjO67OlCIZ3eLsx4I";

//-----------------------------------------------------------

export const path = "/app";
export const version = "/v1";
export const s3Bucket = "noacloud-bucket";
export const maxRegistersPerReport = 30000;

// Resize image parameters
export const maxWidthImage = 400;
export const maxHeightImage = 400;
export const quality = 50;
